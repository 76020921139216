/**
 * HID Authenticator Types
 */

export enum AuthenticatorTypes {
  NONE = 'none',
  AT_STDPWD = 'AT_STDPWD',
  AT_ACTPWD = 'AT_ACTPWD',
  AT_OOBEML = 'AT_OOBEML',
  AT_PASA = 'AT_PASA'
}

export enum AuthDeviceTypes {
  AT_ACTPWD = 'AT_ACTPWD',
  AT_OOBEML = 'AT_OOBEML',
}

export enum OnboardingFlowTypes {
  PASSWORD,
  TEMPORARY_PASSWORD,
  REGISTERED,
}

export enum SubmissionEvents {
  INIT = 'init',
  NEW_SUBMISSION_START = 'new_submission_start',
  SUBMISSION_COMPLETE = 'submission_complete',
  CDD_COMPLETE = 'cdd_complete',
}

export enum HidStatusEvents {
  HID_STATUS = 'hid_status',
}

export enum HIDApproveClientApprovalStatus {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}
