import { Pipe, PipeTransform } from '@angular/core';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import { AuthService, HidAuthenticationService } from '@app/core/services';
import { environment } from '@environments/environment';

@Pipe({
  name: 'getCaptureModeQrUrl',
})
export class GetCaptureModeQrUrlPipe implements PipeTransform {
  constructor(
    private readonly authService: AuthService,
    private readonly hidAuthService: HidAuthenticationService,
  ) {}

  transform(fundsTransfer: FundsTransfer): string {
    return fundsTransfer
      ? `ocra://S:${environment.hidAuth.serviceUrl}\\\\${
          environment.hidAuth.tenantId
        }&${
          this.hidAuthService.userExternalId ||
          this.authService.getCurrentUser()?.userExternalId
        };1:${fundsTransfer.payeeAccountNumber};2:${fundsTransfer.amount};3:${
          fundsTransfer.remarks
        };;`
      : '';
  }
}
