<footer class="sdp-link-footer sdp-footer">
  <!-- <span class="hid-logo footer-hidlogo"></span> -->
  <span class="d-sm-inline">&copy; {{ year }} {{ 'footer.desc1' | translate }} </span>
  <span class="d-sm-inline"
    ><a class="sdp-link" href="https://www.hidglobal.com/about/privacy">{{
      'footer.desc2' | translate
    }}</a></span
  >
  <span class="d-sm-inline"
    ><a class="sdp-link" href="https://www.hidglobal.com/about/terms-of-use">{{
      'footer.desc3' | translate
    }}</a></span
  >
</footer>
