<ng-container *ngIf="standalone">
  <div class="sdp-onboarding-comp-container no-shadows">
    <app-loader></app-loader>

    <div class="sdp-onboarding-comp">
      <div class="sdp-onboarding-comp-header" *ngIf="standalone">
        <div
        src=""
        class="majorbank-logo-blue brand-logo"
       
        ></div>
        <div class="sdp-onboarding-comp-header-title ml-12">
          {{ 'majorBankTitle' | translate }}
        </div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="hidApproveTpl"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!standalone">
  <ng-container *ngTemplateOutlet="hidApproveTpl"></ng-container>
</ng-container>

<ng-template #hidApproveTpl>
  <ng-container [ngSwitch]="activeView">
    <!-- SELECT PRIMARY DEVICE -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.SELECT_PRIMARY_DEVICE">
      <app-select-primary-device
        (viewchange)="onViewChange($event)"
      ></app-select-primary-device>
    </ng-container>

    <!-- HID Approve Push -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH">
      <app-hid-approve-push
        [translationRequired]="translationRequired"
        [message]="message"
        [login]="login"
        [pushMessage]="pushMessage"
        [backBtn]="backBtn"
        [cancelBtn]="cancelBtn"
        [transactionSigning]="transactionSigning"
        (viewchange)="onViewChange($event)"
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
        (rejected)="onRejected()"
        (cancelled)="onCancel()"
      ></app-hid-approve-push>
    </ng-container>

    <!-- HID APPROVE OTP -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP">
      <app-hid-approve-otp
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
        (cancelled)="onCancel()"
        [secureCodeDesc]="secureCodeDesc"
        [cancelBtn]="cancelBtn"
      ></app-hid-approve-otp>
    </ng-container>

    <!-- HID APPROVE TRANSACTION SIGNING OTP -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP">
      <app-transaction-signing-otp
        [fundsTransferInfo]="fundsTransferInfo"
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
      ></app-transaction-signing-otp>
    </ng-container>

    <!-- FLOW END -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.FLOW_END">
      <app-flow-end
        [translationRequired]="translationRequired"
        [message]="successScreenMessage"
        [actionBtnText]="successScreenActionBtnText"
        [finalView]="finalView"
        (flowend)="onFlowEnd()"
      ></app-flow-end>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="deviceSearchTpl"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #deviceSearchTpl>
  <div class="sdp-device-search">
    <div class="text-center">
      <!-- DEVICE SEARCH -->

      <ng-container *ngIf="!noDeviceFound">
        <div class="lds-dual-ring"></div>
        <p>{{ 'hidApprove.deviceSearch' | translate }}</p>
      </ng-container>

      <!-- NO DEVICE FOUND -->

      <ng-container *ngIf="noDeviceFound">
        <span class="text-danger"
          >{{ 'hidApprove.noDeviceFoundLine1' | translate
          }}<a class="sdp-dummy-link-ul" (click)="deleteUser()">{{
            'clickHere' | translate | lowercase
          }}</a
          >{{ 'hidApprove.noDeviceFoundLine2' | translate }}</span
        >
      </ng-container>
    </div>
  </div>
</ng-template>
