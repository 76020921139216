export class Utils {
  /**
   * Generates random string.
   * @description could be used for temporary password.
   * @returns string.
   */
  static generateRandomString(): string {
    return this.generateRandomNumber().toString(36).slice(-16);
  }

  /**
   * Generates random number.
   * @returns number
   */
  static generateRandomNumber() {
    const array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return Number(array.join(''));
  }

  /**
   * Get date from an ISO string by removing the timezone information.
   * @example Input "2021-08-23T08:00:19.946Z", Output "2021-08-23".
   * @param input
   * @returns
   */
  static getDateFromISO(input: string): string {
    return input.substr(0, input.indexOf('T'));
  }

  /**
   * Checks if input string contains sequence of characters.
   * @param input: string
   * @returns boolean
   */
  static containsConsecutiveCharSequence(input: string): boolean {
    const source = input.split('').sort();

    for (let i = 1, j = source.length; i < j; i++) {
      if (source[i].charCodeAt(0) - source[i - 1].charCodeAt(0) !== 1) {
        return false;
      }
    }

    return true;
  }

  /**
   * Checks if input string contains sequence of numbers.
   * @param input: string
   * @returns boolean
   */
  static containsConsecutiveNumberSequence(input: string): boolean {
    if (isNaN(+input)) {
      return false;
    }

    const length = input.length;

    for (let i = 0; i < length / 2; i++) {
      let substr = input.substring(0, i + 1);
      let num = Number(substr);

      while (substr.length < length) {
        num++;
        substr = substr + Number(num);
      }

      if (substr === input) {
        return true;
      }
    }

    return false;
  }

  /**
   * Checks if the number of different characters is greater or equals to the minDiffChars param.
   * @description
   * @param input: string
   * @param minDiffChars: number
   * @returns boolean
   */
  static containsDiffChars(input: string, minDiffChars: number): boolean {
    const chars = input
      .toLowerCase()
      .split('')
      .filter((c: string) => isNaN(+c))
      .sort();

    let diffCharCount = 0;

    for (let i = 0, j = chars.length; i < j; i++) {
      if (chars[i] !== chars[i + 1]) {
        diffCharCount++;
      }
    }

    return diffCharCount >= minDiffChars;
  }
}
