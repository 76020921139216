import { Injectable, Inject, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // Maintains state of bootstrap modal.
  // Used for hide/show of loaders in the parent component
  // when a loader is shown in the modal.
  bsModal$$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly bsModalService: BsModalService,
  ) {}

  public addRMS(): void {
    if (environment.module_rms) {
      const el = this.document.getElementById(environment.hidRMS.id)
      if (!el) {
      const rmsScriptEl = this.document.createElement('script');
      rmsScriptEl.id = environment.hidRMS.id;
      rmsScriptEl.src = environment.hidRMS.sourceUrl;
      this.document.body.appendChild(rmsScriptEl);
      }
    }
   
  }

  public addgMAPS(url:string): void {        
    const gmapsElID = 'GMAPSIFRAMEEL'
    const el = this.document.getElementById(gmapsElID);
    if (!el)
{
      const GmapsEl = this.document.createElement('iframe')
      GmapsEl.id = gmapsElID
      GmapsEl.src = url
      GmapsEl.referrerPolicy = "no-referrer-when-downgrade"
      GmapsEl.classList.add(gmapsElID)



      let pointer = this.document.getElementById('gmapsbracket')
      if (pointer) { pointer.appendChild(GmapsEl);}
      
}
   
  }
  public addCookieConsent(): void {
    if (environment.module_cookie) {
      const el = this.document.getElementById(environment.cookie.id);
      if (!el) {
        const cookScriptEl = this.document.createElement('script');     
        cookScriptEl.id = environment.cookie.id;
        cookScriptEl.src = environment.cookie.sourceUrl;
        this.document.body.appendChild(cookScriptEl);
      }      
    }
  }



  public removeRMS(): void {
    if (environment.module_rms) {
      const el = this.document.getElementById(environment.hidRMS.id);

      if (el) {
        this.document.body.removeChild(el);
      }
    }
  }


  public removeCookieConsent(): void {
    if (environment.module_rms) {
      const el = this.document.getElementById(environment.cookie.id);

      if (el) {
        this.document.body.removeChild(el);
      }
    }
  }

  /**
   * Shows a Ngx Bootstrap Modal with the passed content.
   * @param content TemplateRef
   * @returns BsModalRef
   */
  showModal(content: TemplateRef<any>) {
    return this.bsModalService.show(
      content,
      SDP_CONSTANTS.NGX_MODAL_DEFAULT_CONFIG,
    );
  }

  // Changing the bsModal$$ state to true indicating a modal is OPEN.
  openModal() {
    this.bsModal$$.next(true);
  }

  // Changing the bsModal$$ state to false indicating all of modals are CLOSED.
  closeModal() {
    this.bsModal$$.next(false);
  }

  /**
   * Checks if bootstrap modal is open.
   * @description Used for hiding the Loader i.e. <app-loader> when a modal is open.
   * @returns boolean
   */
  isModalOpen(): boolean {
    return this.bsModal$$.value === true;
  }
}
