<ng-container >
    <div class="sdp-onboarding-comp-container no-shadows">
      <app-loader></app-loader>
  
      <div class="sdp-onboarding-comp">
        
        <div class="sdp-onboarding-comp-header" >
          <div
          src=""
          class="majorbank-logo-white brand-logo"
    
          ></div>
               
          <div class="sdp-onboarding-comp-header-title ml-12">
            {{ 'majorBankTitle' | translate }}
          </div>
        </div>
        <div>
          <ng-container *ngTemplateOutlet="transactionOtp"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

<ng-template #transactionOtp>
<div class="text-center">
    <div class="sdp-ob-form-header">
      <h3 class="sdp-ob-title">
        {{ 'hidTransactionSignSmsOtp.title' | translate }}
      </h3>
      <p class="sdp-ob-intro">
        {{ 'hidTransactionSignSmsOtp.description' | translate }}
      </p>
    </div>
  
      <div class="w-100 mt-4 mb-5 d-flex justify-content-center">
        <div class="form-group text-left">
          <label for="otp">{{
            'hidTransactionSignSmsOtp.title' | translate
          }}</label>
    
          <ng-otp-input
            appNgotpinputKeyEvent
            [submitBtn]="submitBtn"
            #ngOtpInput
            (onInputChange)="onOtpChange($event)"
            [config]="{
              length:8,
              allowNumbersOnly: true
            }"
          ></ng-otp-input>
    
          <div class="invalid-feedback d-block" *ngIf="incorrectOtp">
            {{ 'hidApproveTransSignOtp.incorrectOtp' | translate }}
          </div>
        </div>
      </div>
    </div>
    
    <div class="text-right">
      
        <button class="btn btn-primary-mb mr-3" (click)="onCancelClick()"> 
          {{ 'cancel' | translate }}
        </button>
      <button
        type="submit"
        class="btn btn-primary-mb"
        (click)="onSubmit()"
        [disabled]="
          ngOtpInput?.otpForm?.invalid ||
          otp?.length !== 8 "
        #submitBtn
      >
        {{ 'confirm' | translate }}
      </button>
    </div>
  </ng-template>      
     