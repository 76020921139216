import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appNgotpinputKeyEvent]',
})
export class NgotpinputKeyEventDirective implements AfterViewInit {
  @Input() submitBtn: any;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    const targetEl =
      this.el.nativeElement.querySelector('input.otp-input').parentNode;

    this.renderer.listen(targetEl, 'keypress', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        (this.submitBtn as HTMLButtonElement).click();
      }
    });
  }
}
