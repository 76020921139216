<div class="text-center">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">{{ 'chooseAuthentication.title' | translate }}</h3>
    <p class="sdp-ob-intro">
      {{ 'chooseAuthentication.description' | translate }}
    </p>
  </div>

  <div class="my-5 row">
    <ng-container *ngFor="let authType of authenticationTypes">
      <a
        [ngClass]="{
          activated: !authType.isDisabled,
          selected: authType.selected,
          'no-events': authType.isDisabled,
          'sdp-product-card-wrapper pointer col-sm-12 col-md-6 col-lg-6 col-xl-3': true
        }"
        appHoverImg
        [disabled]="
          authType.title !== SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE
        "
        [noEvents]="authType.selected"
        (click)="onSubmit()"
      >
        <div class="sdp-product">
          <img
            class="img-fluid"
            [src]="
              './../../../assets/images/auth-type/' +
              authType.imageFileName +
              (authType.selected ? '-active' : '') +
              '.svg'
            "
            alt="{{ authType.title | translate }}"
          />
        </div>
        <div class="sdp-product-title">
          {{ authType.title | translate }}
        </div>
      </a>
    </ng-container>
  </div>
  <div class="form-group text-right">
    <ng-container *ngIf="cancelBtn">
      <button class="btn btn-primary-mb mr-3" (click)="onCancelClick()">
        {{ 'cancel' | translate }}
      </button>
    </ng-container>
    <button
      class="btn btn-primary-mb"
      (click)="onSubmit()"
      [disabled]="!isValid()"
    >
      {{ 'submit' | translate }}
    </button>
  </div>
</div>
