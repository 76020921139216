import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseForm } from '@app/core/base/base-form';
import { SDP_CONSTANTS, Utils } from '@app/core/helpers';
import { AuthenticatorTypes } from '@app/core/helpers/enums';
import { UserAttribute } from '@app/core/models';
import {
  OnboardingService,
  ValidationService,
  HidAuthenticationService,
} from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';

const FORM_FIELDS = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
};

const CONSTS = {
  USER_ATTRIBUTE: 'urn:hid:scim:api:idp:2.0:UserAttribute',
  TITLE: 'TITLE',
  AUTHENTICATOR_PASSWORD:
    'urn:hid:scim:api:idp:2.0:policy:authenticator:Password',
  NOT_OLD_PASSWORD: 'notOldPassword',
  ACCESS_TOKEN: 'access_token',
};

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent extends BaseForm implements OnInit {
  @Input() user!: any;
  @Input() authenticatorType!: any;
  @Output() flowtype = new EventEmitter<string>();

  // Actual password policy response received from HID auth service
  passwordPolicy!: any;

  // Contains the password guidelines used for display, and policy validation map
  policy!: { passwordGuidelines: string[]; policyMap: any };

  // Used for 'notUserAttribute' constraint validation
  userAttributes!: string[];

  constructor(
    private readonly fb: FormBuilder,
    private readonly onBoardingService: OnboardingService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this._initForm();
    this._setUserAttributes();
    this.hidAuthService.getPasswordGuidelines(
      this._updatePasswordPolicy.bind(this),
    );
  }

  get passwordCtrl() {
    return this.form.get(FORM_FIELDS.PASSWORD);
  }

  get password() {
    return this.passwordCtrl?.value;
  }

  private _initForm() {
    this.form = this.fb.group(
      {
        [FORM_FIELDS.PASSWORD]: ['', Validators.required],
        [FORM_FIELDS.CONFIRM_PASSWORD]: ['', Validators.required],
      },
      { validators: ValidationService.passwordValidator() },
    );
  }

  private _setUserAttributes() {
    const userAttributes = this.user[CONSTS.USER_ATTRIBUTE].attributes.reduce(
      (acc: string[], curr: UserAttribute) => {
        if (curr.name !== CONSTS.TITLE) {
          acc.push(curr.value.toLowerCase());
        }
        return acc;
      },
      [],
    );

    const emails = this.user.emails.map((email: { value: string }) =>
      email.value.toLowerCase(),
    );

    this.userAttributes = [
      ...userAttributes,
      ...emails,
      this.user.externalId?.toLowerCase(),
      this.user.userName?.toLowerCase(),
    ];
  }

  private _updatePasswordPolicy(passwordPolicyResponse: any) {
    this.passwordPolicy =
      passwordPolicyResponse[CONSTS.AUTHENTICATOR_PASSWORD].passwordpolicy;

    const passwordPolicy = this.onBoardingService.getPasswordPolicy(
      this.passwordPolicy,
    );
    const maxLengthError =
      passwordPolicyResponse[CONSTS.AUTHENTICATOR_PASSWORD].passwordpolicy
        .maxLength;
    const minLengthError =
      passwordPolicyResponse[CONSTS.AUTHENTICATOR_PASSWORD].passwordpolicy
        .minLength;
    const minDiffCharsError =
      passwordPolicyResponse[CONSTS.AUTHENTICATOR_PASSWORD].passwordpolicy
        .minDiffChars;

    passwordPolicy.passwordGuidelines.forEach(
      (guideline: string, index: number) => {
        this.translateService
          .get(guideline, {
            minLength: minLengthError,
            maxLength: maxLengthError,
            minDiffChars: minDiffCharsError,
          })
          .subscribe((text: string) => {
            let val = passwordPolicy.passwordGuidelines[index];
            passwordPolicy.policyMap[val.substr(val.indexOf('.') + 1)].msg =
              text;
            passwordPolicy.passwordGuidelines[index] = text;
          });
      },
    );

    this.policy = passwordPolicy;
  }

  onSubmit() {
    this.submit();

    if (this.form.invalid || !this.isPasswordValid()) {
      return;
    }

    if (this.authenticatorType === AuthenticatorTypes.AT_STDPWD) {
      this.hidAuthService.checkAndResetPassword(
        this.password,
        this._postSubmit.bind(this),
        this._errorCb.bind(this),
      );
    } else {
      this.onBoardingService.password = this.password;
      this._postSubmit();
    }
  }

  private _errorCb(error: HttpErrorResponse) {
    if (error.error.scimType === 'invalidValue') {
      this.policy.policyMap.notOldPassword.valid = false;
    }
  }

  private _postSubmit() {
    if (this.authenticatorType === AuthenticatorTypes.AT_STDPWD) {
      this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.PASSWORD);
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END);
    } else {
      this.flowtype.emit(SDP_CONSTANTS.FLOW_TYPES.TEMPORARY_PASSWORD);
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.ADD_DEVICE);
    }
  }

  validate(password: string): void {
    this.policy.policyMap.notOldPassword.valid = undefined;

    this.policy.policyMap.minMaxLength.valid =
      password.length >= this.passwordPolicy.minLength &&
      password.length <= this.passwordPolicy.maxLength;

    if (this.passwordPolicy.notSequence) {
      let valid = true;

      if (Utils.containsConsecutiveCharSequence(password)) {
        valid = false;
      }

      if (valid && Utils.containsConsecutiveNumberSequence(password)) {
        valid = false;
      }

      this.policy.policyMap.notSequence.valid = valid;
    }

    if (this.passwordPolicy.minDiffChars) {
      this.policy.policyMap.minDiffChars.valid = Utils.containsDiffChars(
        password,
        this.passwordPolicy.minDiffChars,
      );
    }

    if (this.passwordPolicy.notUserAttribute) {
      const passwordLowerCase = password.toLowerCase();

      this.policy.policyMap.notUserAttribute.valid =
        !this.userAttributes.includes(passwordLowerCase);
    }

    if (!this.passwordPolicy.notEnglish) {
      this.policy.policyMap.notEnglish.valid = /^[A-Za-z]+$/.test(password);
    }
  }

  isPasswordValid(): boolean {
    const policyMap = this.policy?.policyMap;

    let valid = true;

    for (const constraint in policyMap) {
      if (
        constraint !== CONSTS.NOT_OLD_PASSWORD &&
        policyMap.hasOwnProperty(constraint) &&
        !policyMap[constraint].valid
      ) {
        valid = false;
        break;
      }
    }

    return valid;
  }
}
