import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SDP_CONSTANTS } from './../helpers';

/**
 * Base class with common properties which would be required
 * in most of the classes. 
 */

@Component({
  selector: 'app-base',
  template: '',
})
export class Base {
  // To make SDP_CONSTANTS available for use in template files. 
  SDP_CONSTANTS = SDP_CONSTANTS;

  // Being used heavily in onboarding module.
  @Output() viewchange = new EventEmitter<string>();

  // For holding client side validation errors for display purpose.
  error$$ = new Subject<string>();
}
