
<ng-container *ngIf="standalone">

  <div class="sdp-onboarding-comp-container no-shadows">
    <app-loader></app-loader>

    <div class="sdp-onboarding-comp">
      <div class="sdp-onboarding-comp-header" *ngIf="standalone">
        <div class="sdp-onboarding-comp-header-title ml-12">
          {{ 'majorBankTitle' | translate }}
        </div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="addDeviceTpl"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!standalone">
  
  <ng-container *ngTemplateOutlet="addDeviceTpl"></ng-container>
</ng-container>

<ng-template #addDeviceTpl>
 
  <ng-container [ngSwitch]="activeView">





    <!-- CHOOSE AUTHENTICATION -->
    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.ADAPTIVE_AUTHENTICATION"> 
    <app-hid-approve
        message="Please verify your idenity to add new device"
        successScreenMessage="New Device added"
        [trustedDevice]="true"
        pushMessage="Please confirm new add device request."
        [standalone]="false"
        [backBtn]="false"
        [cancelBtn]="true"
        [successScreen]="false"
        [showProcessing]="false"
        [successScreenActionBtnText]="'flowEnd.continue'"
        successScreenMessage="You have approved adding new trusted device."
        (showhidapprovetargetview)="ApproveConfirm()"
        (flowend)="onApproveConfirm()"
        (rejected)="onCancelled()"
        (cancelled)="onCancelled()"
      ></app-hid-approve>
    </ng-container>
    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION">
    
      <app-choose-authentication
        [cancelBtn]="cancelBtn"
        (viewchange)="onViewChange($event)"
        (cancelled)="onCancelled()"
      ></app-choose-authentication>
    </ng-container>

    <!-- CONNECT HID APPROVE -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.CONNECT_HID_APPROVE">
     
      <app-connect-hid-approve
        [cancelBtn]="cancelBtn"
        (viewchange)="onViewChange($event)"
        (cancelled)="onCancelled()"
      ></app-connect-hid-approve>
    </ng-container>

    <!-- FLOW END -->

    <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.FLOW_END">
      <app-flow-end
        [message]="successScreenMessage"
        [actionBtnText]="successScreenActionBtnText"
        (flowend)="onFlowEnd()"
      ></app-flow-end>
    </ng-container>
  </ng-container>
</ng-template>
