
  <ng-container>

    

    <ng-container [ngSwitch]="activeView">
      <!-- CHOOSE AUTHENTICATION -->
  
      <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION">
        <app-choose-authentication-trans
        [cancelBtn]="true"
        (cancelled)="onCancelled()"
        (viewchange) = "onViewChange($event)"
        ></app-choose-authentication-trans>
      </ng-container>
  
      <!-- HID APPROVE FLOW--> 
  
      <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.HID_APPROVE_FLOW">
        <app-hid-approve
        [transactionSigning]="true" [fundsTransferInfo]="fundsTransferInfo"
        [translationRequired]="false" [message]="message" [successScreenMessage]="successScreenMessage"
        [pushMessage]="pushMessage" [standalone]="true" [backBtn]="false" [cancelBtn]="true" [successScreen]="true"
        [successScreenActionBtnText]="successScreenActionBtnText" [showProcessing]="true" [processingDone]="processingDone"
        (showhidapprovetargetview)="onShowHIDApproveTargetView()" (rejected)="onCancelled()" (flowend)="onFlowEnd()"
        (cancelled)="onCancelled()"
        ></app-hid-approve>
      </ng-container>

      <!-- TRANACTION SIGING USING SMS -->

      <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.TRANSACTION_SIGN_SMSOTP">
        <app-transaction-smsotp
        (cancelled)="onCancelled()"
        [fundsTransferInfo]="fundsTransferInfo" 
        (showhidapprovetargetview)="onShowHIDApproveTargetView()"
        [pushMessage]="pushMessage"
        ></app-transaction-smsotp>
      </ng-container>

      <!-- FLOW END -->
  
      <ng-container *ngSwitchCase="SDP_CONSTANTS.VIEWS.FLOW_END">
        <app-flow-end
          [message]="successScreenMessage"
          [actionBtnText]="successScreenActionBtnText"
          (flowend)="onFlowEnd()"
        ></app-flow-end>
      </ng-container>
    </ng-container>
  <!-- </ng-template> -->
  