import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Base } from '@app/core/base/base';
import { HidAuthenticationService } from '@app/core/services';

@Component({
  selector: 'app-hid-approve-otp',
  templateUrl: './hid-approve-otp.component.html',
  styleUrls: ['./hid-approve-otp.component.scss'],
})
export class HidApproveOtpComponent extends Base {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @Input() view!: string;
  @Input() secureCodeDesc!: string;
  @Output() showhidapprovetargetview = new EventEmitter();
  @Input() cancelBtn!: boolean;
  @Output() cancelled = new EventEmitter();

  otp!: any;
  incorrectOtp = false;

  constructor(private readonly hidAuthService: HidAuthenticationService) {
    super();
  }

  onOtpChange(otp: string) {
    this.otp = otp;
  }

  onSubmit() {
    this.incorrectOtp = false;

    if (this.ngOtpInput?.otpForm?.invalid) {
      return;
    }

    this.hidAuthService.authenticateHIDApproveSecureCode(
      this.otp,
      this._successCb.bind(this),
      this._errorCb.bind(this),
    );
  }

  private _successCb() {
    this.showhidapprovetargetview.emit();
  }

  private _errorCb() {
    this.incorrectOtp = true;
  }

  onCancelClick() {
    this.cancelled.emit();
  }
}
