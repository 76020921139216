
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  HidRmsDataService,
} from '@app/core/services';
import * as uuid from 'uuid';
import { CookieService} from 'ngx-cookie-service'



@Injectable({
  providedIn: 'root',
})
export class HidRmsService {
    /**
   * HID RMS Service Module
   * @description Provdies RMS Functions to user journeys.
   */
  userInternalId!: number;
  userExternalId!: string;
  user!: any;
  TMdevicetag!:string;
  TMSessionsid!:string;
  score!: any;
  scoringSession!:Array<object>;
  scoring!:Array<object>;
  scoringseen!:Array<number>;
  sessionlog!:any;
  tm_action_id!:string;
  last_app_action_id!:string;  
  payment_scoring!:any;
  action_scoring!:any;
  constructor(
    private readonly HidRmsDataService: HidRmsDataService,
    private readonly cookieService:CookieService,    
  ) {
    this._readTMcookies()
  
  }
  
  private _pushTMAdata(action:any, userid:any){    
    this.tm_action_id = action.tm_action_id   
    if (!this.scoringSession) { this.scoringSession = []}
    this.scoringSession.push(action)
 
  }    
       
  

  private _readTMcookies(){    
    this.TMdevicetag = this.cookieService.get(environment.hidRMS.tm_device_tag)
    this.TMSessionsid = this.cookieService.get(environment.hidRMS.tm_session_id)     
    return
  }
  

  

  private _sessionContextMaker(appsessionid:string){
    // read TMS cookies 
    // cook session context
    // return session context

    this._readTMcookies()
  
    const session_context = {
      app_session_id: appsessionid,
      app_session_sid: appsessionid,     
      tm_device_tag: this.TMdevicetag,
      tm_session_sid:  this.TMSessionsid 
    }

    return session_context
  }

  private _getAppActionId(){
    const aaid = uuid.v4()
    this.last_app_action_id = aaid
    return aaid
  }

  readHistory(
    userid:any,
     successCB: () => void,
    ){     
    this.HidRmsDataService
      .sessionHistory(userid)      
      .subscribe(
        (response:any) => {    
          if (response[0]){                         
            for (let item in response) {             
              let it = {}
              it = JSON.parse(response[item])
              this.scoring[0]= it
            }
           } else{ this.scoring = []}

            let the =  {records: this.scoring, seen: this.scoringseen }       
            this._success(the, successCB)
        })
      }         

      getHistory(){
        return {records: this.scoring, seen: this.scoringseen }  }
      

  setHistoryRead(i:any){
    this.scoringseen.push(i)
  }

  clearUser() {
    this.user = undefined;
    this.userExternalId = '';
    this.userInternalId = -1;
    this.scoring = []
    this.tm_action_id=''
    this.last_app_action_id=''
    this.user = {}
  }
 
  setUser(userRawResponse: any) {
    const user = userRawResponse.resources[0];
    this.user = user;
    this.userExternalId = user.externalId;
    this.userInternalId = Number(user.id);
  }

  getUser() {
    return this.user;
  }  

  loginStep(          userid: any, 
                      stepnumber: number,                    
                      stepresult: string,
                      tokenid: string, 
                      tokentype: string,     
                      appsessionid: string,                 
                      successCB: () => void,
   ) {         
      const useralias = userid 
      const appactionid = this._getAppActionId()      
      const sessioncontext = this._sessionContextMaker(appsessionid)   
      this.HidRmsDataService
          .sessionCreateStep(userid,
              appactionid,
              stepnumber,
              useralias,
              tokenid,
              tokentype,
              stepresult,
              sessioncontext)
            .subscribe(
              (response:any) => {
                this._pushTMAdata(response, userid)
                this._success(response, successCB)
              },
              _ => {}, 
            )
    }

    loginSuccess(       userid: any, 
                      decision: string,                               
                      appsessionid: string,     
                      successCB: () => void,                
   ) {        
    const signedappactionid = this.last_app_action_id
    const tmactionid = this.tm_action_id
    const sessioncontext = this._sessionContextMaker(appsessionid) 
      this.HidRmsDataService
        .sessionLogin(userid,
              decision,
              tmactionid,
              signedappactionid, 
              sessioncontext)
        .subscribe(
                (response:any) => this._success(response, successCB),
                _=> {},
                )
      };


  loginReject(  
    userid: any, 
    authenticationscheme: string,   
    tmactionid: string, 
    signedappactionid: string,                      
    sessioncontext: string,
    successCB: () => void,
    errorCb: () => void,
) {    
 
   

    this.HidRmsDataService
      .sessionReject(userid,
      authenticationscheme,
      tmactionid,  
      signedappactionid,               
      sessioncontext)
        .subscribe(
        (response:any) => this._success(response, successCB),
        _=> errorCb?.(),
        )

}

  logout(       
    /**
   * RMS Logout SIgnal
   * @description RMS Logout Signal - expects userid and sessionid.
   */
    userid: any,                     
    appsessionid: any,
    
  ) {      
  const sessioncontext = this._sessionContextMaker(appsessionid) 
  const appactionid = this._getAppActionId()
  this.HidRmsDataService
    .sessionLogout(userid,
                  appactionid,
                  sessioncontext)        
    .subscribe(
        (response:any) => {
          this.clearUser()
          this._success(response,()=>{})
        },
        _=> ()=>{}
        )
    };

    getSession(){
      return this.sessionlog;
  }
    

  sessionInfo(    
        
      appsessionid: any,   
      successCB: () => void,
     
    ) {    
   
    const sessioncontext = this._sessionContextMaker(appsessionid) 
    this.HidRmsDataService
      .sessionInfo( sessioncontext)        
      .subscribe(
          (response:any) => 
              {this.sessionlog = response;
              this._success(response, successCB)},
          () => {}
          )
      };
      getScore() {
        return this.score;
      }
  sessionscore(               
        userid: any, 
        appsessionid: any,                
        successCB: () => void,
        
      ) {    
      const sessioncontext = this._sessionContextMaker(appsessionid) 
      const appactionid = this._getAppActionId()
      this.HidRmsDataService
        .sessionScore(userid,
                      appactionid,
                      sessioncontext)        
        .subscribe(
            (response:any) => {this.score = response; this._success(response, successCB)},_=> {},
            )
        };
  
  userinfo(    
           userid: any,                             
    
          successCB: () => void,
          errorCb: () => void,
        ) {    
        
        this.HidRmsDataService
          .userInfo(userid)        
          .subscribe(
              (response:any) => this._success(response, successCB),
              _=> errorCb?.(),
              )
          };
     
  userdelete(   
        userid: any,                               
        
        successCB: () => void,
        errorCb: () => void,
      ) {    
       
       
      this.HidRmsDataService
        .userDelete(userid)        
        .subscribe(
            (response:any) => this._success(response, successCB),
            _=> errorCb?.(),
            )
        };




getPaymentScore(){
  return this.payment_scoring;
}


paymentCreate(
  userid: any, 
  appsessionid: any,    
  paymentarray: any,
  successCB: () => void,
 
){
  
    const sessioncontext = this._sessionContextMaker(appsessionid) 
   //const authorizationTMactionID = this.last_app_action_id
    const appactionid = this._getAppActionId()  
 
    this.HidRmsDataService
        .paymentCreate(userid,
                      appactionid,
                     // authorizationTMactionID,
                      paymentarray,
                      sessioncontext)      
        .subscribe(
                        (response:any) => {                                                            
                                this.payment_scoring=response
                                this._success(response, successCB) 
                              },
                              (error:any) => {       
                                window.console.log(error)   }
                        )  
  }
  

  
paymentSign(userid: any, 
  appsessionid: any,    
  app_payment_id: any,    
  payment_state: any,    
   sigstate: any,    
                    sigdate: any,    
                    tokenid: any,    
                    tokentype: any,    

  successCB: () => void,
){
  
    const sessioncontext = this._sessionContextMaker(appsessionid) 
   //const authorizationTMactionID = this.last_app_action_id
    const appactionid = this._getAppActionId()  
 
    this.HidRmsDataService
        .paymentSign(userid,
                    appactionid,
                    app_payment_id,
                    payment_state,
                    sigstate,
                    sigdate,
                    tokenid,
                    tokentype,
                    sessioncontext)      
        .subscribe(
                        (response:any) => {                                                            
                                this.payment_scoring=response
                                this._success(response, successCB) 
                              },
                              (error:any) => {       
                                window.console.log(error)   }
                        )  
  }




  paymentComplete(app_payment_id:any, payment_status:any,appsessionid:any, successCB: () => void) {
    const sessioncontext = this._sessionContextMaker(appsessionid) 
    this.HidRmsDataService
    .paymentComplete(  
      app_payment_id,
      payment_status,
      sessioncontext)      
    .subscribe(
                    (response:any) => {                                                           
                           
                            this._success(response, successCB) 
                          },
                          (error:any) => {       
                            window.console.log(error)   }
                    )  
}  



actionCreate(
  userid: any, 
  appsessionid: any,    
  action: object,
  successCB: () => void,
 
){
  
    const sessioncontext = this._sessionContextMaker(appsessionid) 
   //const authorizationTMactionID = this.last_app_action_id
    const appactionid = this._getAppActionId()  
 
    this.HidRmsDataService
        .actionCreate(userid,
                      appactionid,
                     // authorizationTMactionID,
                     action,
                      sessioncontext)      
        .subscribe(
                        (response:any) => {                                                            
                                this.action_scoring=response
                                this._success(response, successCB) 
                              },
                              (error:any) => {       
                                window.console.log(error)   }
                        )  
  }
  

  
actionSign(userid: any,
  appsessionid:any,
 // authorization_tm_action_id:any,
  factor_index:any,      
  signature_result:any,

  tokenid:any,
  tokentype:any,     

  successCB: () => void,
){
  
    const sessioncontext = this._sessionContextMaker(appsessionid) 
   //const authorizationTMactionID = this.last_app_action_id
    const appactionid = this._getAppActionId()  
 
    this.HidRmsDataService
        .actionSign(userid,
          appactionid,
          //authorization_tm_action_id,
          factor_index,
          signature_result,
      
          tokenid,
          tokentype,
          sessioncontext)      
        .subscribe(
                        (response:any) => {                                                            
                                this.action_scoring=response
                                this._success(response, successCB) 
                              },
                              (error:any) => {       
                                window.console.log(error)   }
                        )  
  }




  actionComplete(userid: string,
    decision: string,
    //signed_tm_action_id:string,  
   
    successCB: () => void) {
      const appactionid = this._getAppActionId()  
    this.HidRmsDataService
    .actionComplete(    userid,
      decision,
     // signed_tm_action_id,
      appactionid)      
    .subscribe(
                    (response:any) => {                                                           
                           
                            this._success(response, successCB) 
                          },
                          (error:any) => {       
                            window.console.log(error)   }
                    )  
}  



 
private _success(response: any, successCb: () => void) {
   successCb?.();
  }    


}
