import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute,RouterEvent, Event } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { HidRmsService } from '@app/core/services/hid-rms/hid-rms.service';
import { SystemService } from '@app/core/services/systemStatus/systemStatus.service';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SDP_CONSTANTS } from '../../helpers';
import { environment } from '@environments/environment';
import { SharedService } from '@app/core/services';
import { DeviceDetectorService } from 'ngx-device-detector';

// Main Performance Optimization Target
// Use 


const RESTRICTED_ROUTES = [SDP_CONSTANTS.ROUTES.ONBOARDING].map(t => `/${t}`);
interface IProduct {
  title: string;
  imageFileName: string;
  redirectLink?: string;
  routerLink?: string;
  enabled?: boolean;
}

interface IDetection {
    id:string;
    type:string;
}

interface IRMSData {
  android_device?: object;
  geoip?: { city?: string; country_code?:string; latitude?:number; longitude?:number};
  geolocation?: object;
  ios_device?: object;
  ip: { endpoint?:string; proxy_pierce?: string };
  isp?: string;
  tm_device_id?: string,
  web_device?: object;
}
interface IRMSScore {
  data: {
    all_data_collected: string;
    collected_data?: Array<string>;
    expected_data?: Array<string>;

  };
  scoring_result: {
      case_id?: string;
      risk: string;
      device: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
      session: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
      user: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
  }

}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  logoutEnabled: Observable<boolean> = of(false);
  products: IProduct[] = [    
    {
      title: SDP_CONSTANTS.PRODUCT_TITLES.AUTH_ADMIN_PORTAL,
      imageFileName: 'authentication-management.svg',
      redirectLink: environment.authenticationManagementConsole,
      enabled: environment.module_auth,
    },
    {
      title: SDP_CONSTANTS.PRODUCT_TITLES.RISK_MGMT_SOL_DASHBOARD,
      imageFileName: 'risk-management.svg',
      redirectLink: environment.riskManagementSolutionDashboard,
      enabled: environment.module_rms,
    },        
    {
      title: SDP_CONSTANTS.PRODUCT_TITLES.IDENTITY_VERIFICATION,
      imageFileName: 'digital-onboarding.svg',
      redirectLink: environment.IDV2management,
      enabled: environment.module_idv2,
    },
  ];
  isMobile!: boolean;
  rmsdata!: IRMSData;
  rmsscore!: IRMSScore;
  public optionsShow:boolean = false;
  public optionsShow1:boolean = false;
  public optionsShow2:boolean = false;
  public rmsShow:boolean = false;
  public rmsScoreShow:boolean = false;
  public buttonName:any = 'HID Technology';
  public buttonName1:any = 'Show Full API Response';
  private gmapsAPI:string = "AIzaSyBzPihmP17zgi_xLg8VkzyYtwjpOtR4Wmg";
  TimerSessionInfo!:any;
  run: any = { sess: 0,  score:0   }
  public alive: boolean = true;
  private sidealive:  boolean = true;
  private aliveCheckInterval = 10000;
  public idvonly: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly hidRmsService:HidRmsService,
    private readonly activatedRoute:ActivatedRoute,
    private readonly sharedService:SharedService,
    private readonly systemService:SystemService,
    public readonly deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.sharedService.addCookieConsent()
    
    if (!(environment.module_auth) && environment.module_idv2){ this.idvonly = true}
    this.isMobile = this.deviceDetectorService.isMobile()   

    if (environment.module_rms) {
      this._RMSRefresh()
      this.sharedService.addRMS()      
    }

     if (!this.isMobile) {
      this.systemService.checklive()
       setTimeout(()=>{this._isAlive() },5000)
       setInterval(()=>{ this._isAlive()},this.aliveCheckInterval)
     }

    this.logoutEnabled = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      map(
        (event: Event) =>
          this.authService.isSessionAlive() &&
          !RESTRICTED_ROUTES.includes((event as RouterEvent).url),
      ),
    );
  }

  private _RMSRefresh(){  
      this.loadRMSSession()
      this.loadRMSScore()
  }


  private _isAlive(){
    this.alive = this.systemService.isAlive() 

  
    if (!this.alive) { this.sidealive = this.alive; window.console.log('Backend is down. Please Wait. Site Will reload')  }
    if(this.alive && !this.sidealive) {
        window.console.log('Backend is up. Redirecting home')
        window.location.href = "/";
    }
  }

  private _RMSSessionData(){ 
    this.run.sess = 0    
    let RMSdata = this.hidRmsService.getSession()
    this.rmsdata = RMSdata;
    if (this.rmsdata) { this.rmsShow = true;} else { setTimeout(()=>{this.loadRMSSession()},1500)}
  }

  private _RMSScoreData(){ 
    this.run.score = 0
    let RMSdata = this.hidRmsService.getScore()
    this.rmsscore = RMSdata;
    if (this.rmsscore) { this.rmsScoreShow = true;} else { setTimeout(()=>{this.loadRMSScore()},1500)}
    
  }

  loadRMSSession(){
    
    this.hidRmsService.sessionInfo(this.authService.getVisit().tttt,this._RMSSessionData.bind(this))  
    
  }

  loadRMSScore(){   
   
    if( this.authService.getCurrentUser()?.userExternalId) { 
      this.hidRmsService.sessionscore(this.authService.getCurrentUser()?.userExternalId, this.authService.getVisit().tttt, this._RMSScoreData.bind(this)) 
    
  }
  }

  onLogoutBtnClick() {
    if (environment.module_rms) {       
      this.hidRmsService.logout(this.authService.getCurrentUser()?.userExternalId,this.authService.getVisit().tttt)      
    }      
    this.authService.logout();  
  }

  onToggleSettings() {
    if (environment.module_rms) { this._RMSRefresh() }
    this.optionsShow = !this.optionsShow;

    // Change the name of the button.
    if(this.optionsShow)  {
      this.buttonName = "HID Technology (x)";
    } else {
      this.buttonName = "HID Technology";
  }
}

  onToggleDetail1() {
    this.optionsShow1 = !this.optionsShow1;

    // Change the name of the button.
   
  }
  onToggleDetail2() {
    this.optionsShow2 = !this.optionsShow2;

    // Change the name of the button.
   
  }


  onProductClick(product: IProduct, event1: Event) {
    if (!product.redirectLink && product.routerLink) {     
      this.router.navigate([`/${product.routerLink}`], {
        relativeTo: this.activatedRoute,
      });
    }
  }
  ngOnDestroy() {
    this.sharedService.removeRMS()
  }
}
