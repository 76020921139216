import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { SDP_CONSTANTS, Utils } from '@app/core/helpers';
import {
  HidAuthDataService,
  HidAuthenticationService,
  LoaderService,
  OnboardingService,
  HidDeviceDataService,
  AuthService,
} from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AddDeviceBase } from '../add-device-base.component';
import { HIDAuthServiceCallbackEvent } from '@app/core/models/hid-auth-service-callback-event';

@Component({
  selector: 'app-connect-hid-approve',
  templateUrl: './connect-hid-approve.component.html',
  styleUrls: ['./connect-hid-approve.component.scss'],
})
export class ConnectHidApproveComponent
  extends AddDeviceBase
  implements OnInit, OnDestroy
{
  @Input() cancelBtn = false;
  @Output() cancelled = new EventEmitter();

  // Contains data from which QR Code is generated
  qrCodeData!: any;

  // Contains manual invite data like userId, inviteCode, serviceUrl
  manualCode: any;

  qrCodeLabel!: string;
  manualCodeLabel!: string;
  qrDesc!: boolean;
  manualDesc!: boolean;
  isMobile!: boolean;
  email!:string;
  timer!:any;
  timeout:boolean = false;
  constructor(
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly translateService: TranslateService,
    private readonly onBoardingService: OnboardingService,
    private readonly hidAuthDataService: HidAuthDataService,
    private readonly hidAuthService: HidAuthenticationService,
    private readonly hidDeviceDataService: HidDeviceDataService,
    public readonly deviceDetectorService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnInit(): void {
  
    this.isMobile = this.deviceDetectorService.isMobile()   

    this.qrDesc = true;
    this.manualCode = { userId: '', inviteCode: '', serviceUrl: ''};
    this.qrCodeLabel = this.translateService.instant(
      'chooseAuthentication.qrCode',
    );
    this.manualCodeLabel = this.translateService.instant(
      'chooseAuthentication.manualCode',
    );

    this.registerDevice();
  }

  registerDevice() {
      this.hidAuthService.searchUser(
      environment.hidAuth.clientId,
      this._searchUserSuccess.bind(this),
    );

    this.qrCodeLabel = this.translateService.instant(
      'chooseAuthentication.qrCode',
    );
    this.manualCodeLabel = this.translateService.instant(
      'chooseAuthentication.manualCode',
    );
  }

  private _searchUserSuccess(response: any) {    
    if (!!response.totalResults) {
      this._setClientIdCallbackURL(response.resources[0].id);
    }
  }

  private _setClientIdCallbackURL(internalId: number) {
    this.loaderService.show();

    this.hidAuthDataService
      .updateUser(
        this.hidAuthService.createUpdateUserAttributesForCallback(),
        internalId,
      )
      .subscribe((_: any) => {
        this._createDevice();
      });
  }

  private _createDevice() {
    const deviceExternalId = `${
      this.hidAuthService.userExternalId ||
      this.authService.getCurrentUser()?.userExternalId
    }-${Math.floor(1000 + Math.random() * 9000)}`;
    this.hidDeviceDataService.deviceExternalId = deviceExternalId;
    this.hidDeviceDataService
      .createDeviceType(this.hidDeviceDataService.createDeviceTypePayload())
      .subscribe((response: any) => {
        this.hidDeviceDataService.devicePushId = response.id;
        this._bindDevice();
      });
  }

  private _bindDevice() {
    this.hidDeviceDataService
      .bindDevice(
        this.hidDeviceDataService.createBindDevicePayload(
          this.hidAuthService.userExternalId ||
            this.authService.getCurrentUser()?.userExternalId,
        ),
      )
      .subscribe((response: any) => {
        if (response) {
          this.hidDeviceDataService.owner = response.owner.value;
          this._createDeviceProvision();
        }
      });
  }

  private _createDeviceProvision() {
    this.hidDeviceDataService
      .createDeviceProvision(
        this.hidDeviceDataService.createDeviceProvisonPayload(),
      )
      .subscribe((response: any) => {
        if (response) {
          this.loaderService.hide();
          this.qrCodeData = response.attributes[0].value;
          const qrCodeJson = JSON.parse(this.qrCodeData);
          this.manualCode.userId = qrCodeJson.uid;
          this.manualCode.inviteCode = atob(qrCodeJson.pss);
          this.manualCode.serviceUrl = qrCodeJson.url;
      
          this.email = this.hidAuthService.userExternalId 
          this._registerForNewCallback()
          // changed timeout for approve registration to  1200s
          setTimeout(()=> this._closeCallback(), 1200000)
          this.timer = setInterval(()=> this._registerForNewCallback(),2000)        
       
        }
      });
  }


  private _closeCallback(){    
    clearInterval(this.timer);
  }

  private _registerForNewCallback(){
    
    this.hidDeviceDataService.checkCallback(this.email).subscribe((response: any) =>{
     
        if (response) {
          
          this.hidDeviceDataService.doneCallback(this.email).subscribe((response1: any) =>{
            this._closeCallback()
            this.hidAuthService.checkAndResetPassword(
              this.onBoardingService.password,
            this._successCb.bind(this),
            );
          })
        }      
      })
    }

  



  private _successCb() {
    this.onBoardingService.setFlowType(SDP_CONSTANTS.FLOW_TYPES.REGISTERED);
    this.viewchange.emit(SDP_CONSTANTS.VIEWS.FLOW_END);
  }

  fetchTab(event: Event) {
    var element = event.target as HTMLElement;
    if (element.innerText === 'QR Code' || element.innerText === 'Link') {
      this.qrDesc = true;
      this.manualDesc = false;
    } else {
      this.qrDesc = false;
      this.manualDesc = true;
    }
  }

  onCancelClick() {
    this.cancelled.emit();
  }

  ngOnDestroy() {

  }
}
