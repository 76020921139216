import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseForm } from '@app/core/base/base-form';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { HidDeviceDataService } from '@app/core/services/hid-device-data/hid-device-data.service';

const FORM_FIELDS = {
  DEVICE: 'device',
};

@Component({
  selector: 'app-select-primary-device',
  templateUrl: './select-primary-device.component.html',
  styleUrls: ['./select-primary-device.component.scss'],
})
export class SelectPrimaryDeviceComponent extends BaseForm implements OnInit {
  devices!: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly hidDeviceDataService: HidDeviceDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.devices = this.hidDeviceDataService.devices;
    this._initForm();
  }

  private _initForm() {
    this.form = this.fb.group({
      [FORM_FIELDS.DEVICE]: [null, Validators.required],
    });
  }

  get preferredDevice() {
    return this.form.get(FORM_FIELDS.DEVICE)?.value;
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.hidDeviceDataService.selectedDevice = this.devices.find(
      (device: any) => device.id === this.preferredDevice,
    );

    this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH);
  }
}
