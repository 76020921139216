import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [ProductsComponent],
  imports: [CommonModule, TranslateModule, SharedModule],
})
export class ProductsModule {}
