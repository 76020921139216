export enum InputTypes {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum InputLabels {
  SHOW = 'Show',
  HIDE = 'Hide',
}

export enum IconTypes {
  EYE = 'bi bi-eye',
  EYE_SLASH = 'bi bi-eye-slash',
}

export type InputLabel = InputLabels.SHOW | InputLabels.HIDE;
export type IconType = IconTypes.EYE | IconTypes.EYE_SLASH;

export interface InputElement {
  text: Property;
  password: Property;
}

export interface Property {
  type: string;
  label: string;
  icon: string;
}

export const CONSTS = {
  TYPE: 'type',
  INPUT: 'input',
};
