import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SDP_CONSTANTS } from '@core/helpers';
import { Base } from '@app/core/base/base';
// import { AddDeviceBase } from '../add-device-base.component';

interface IAuthenticationType {
  title: string;
  imageFileName: string;
  selected: boolean;
  isDisabled: boolean;
}
@Component({
  selector: 'app-choose-authentication-trans',
  templateUrl: './choose-authentication-trans.component.html',
  styleUrls: ['./choose-authentication-trans.component.scss']
})

export class ChooseAuthenticationTransComponent extends Base implements OnInit 
{
  @Input() cancelBtn = false;
  @Input() loginAdaptive = false;
  @Output() cancelled = new EventEmitter();
  @Output() viewchange = new EventEmitter();

  isSmsClicked = false;
  isAuthChecked = false;
  isHIDClicked = false;

  authDisable = false;
  // Contains all the authentication types.
  // HID Approve, Email, SMS, Hardware Token
  authenticationTypes!: IAuthenticationType[];

  // Contains only active authentication types
  // filtered by isDisabled flag
  activeAuthTypes!: IAuthenticationType[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.createAuthenticationType();
    this._setActiveAuthTypes();
  }

  createAuthenticationType() {
    this.authenticationTypes = [
      {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
        imageFileName: 'approve',
        selected: false,
        isDisabled: this.authDisable,
      },
      // {
      //    title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS,
      //    imageFileName: 'sms',
      //   selected: false,
      //    isDisabled: true,
      //  },
      // // {
      //    title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.EMAIL,
      //   imageFileName: 'email',
      //    selected: false,
      //    isDisabled: true,
      //  },
      //  {
      //    title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HARDWARE_TOKEN,
      //    imageFileName: 'hardware-token',
      //  selected: false,
      //  isDisabled: true,
      //  },      

    ];
  }

  private _setActiveAuthTypes() {
    this.activeAuthTypes = this.authenticationTypes.filter(
      (authType: IAuthenticationType) => !authType.isDisabled,
    );
  }

  onSubmit() {

    if (this.isHIDClicked) {
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_FLOW);
    // }else if(this.isSmsClicked) {
    //   this.viewchange.emit(SDP_CONSTANTS.VIEWS.TRANSACTION_SIGN_SMSOTP);
    }else{
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.HID_APPROVE_FLOW);
    }
  }

  gotonext(authType: any){
    if((authType.title === SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS)){
      this.isSmsClicked = true; this.isHIDClicked = false;
      this.isAuthChecked = true;
      this.authenticationTypes = [
        {
          title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
          imageFileName: 'approve',
          selected: false,
          isDisabled: this.authDisable,
        },
      //   {
      //     title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS,
      //     imageFileName: 'sms',
      //     selected: true,
      //     isDisabled: this.authDisable,
      //   },
      //   {
      //     title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.EMAIL,
      //     imageFileName: 'email',
      //     selected: false,
      //     isDisabled: true,
      //   },
      //   {
      //     title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HARDWARE_TOKEN,
      //     imageFileName: 'hardware-token',
      //     selected: false,
      //     isDisabled: true,
      //   },      
  
      ];
   }
    else if(authType.title === SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE)  {
      this.isHIDClicked = true;this.isSmsClicked = false;
      this.isAuthChecked = true;
      this.authenticationTypes = [
        {
          title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
          imageFileName: 'approve',
          selected: true,
          isDisabled: this.authDisable,
        },
        // {
        //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS,
        //   imageFileName: 'sms',
        //   selected: false,
        //   isDisabled: this.authDisable,
        // },
        // {
        //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.EMAIL,
        //   imageFileName: 'email',
        //   selected: false,
        //   isDisabled: true,
        // },
        // {
        //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HARDWARE_TOKEN,
        //   imageFileName: 'hardware-token',
        //   selected: false,
        //   isDisabled: true,
        // },      
  
      ];
    }
  }

  isValid() {
    return (
      this.activeAuthTypes.filter(
        (authType: IAuthenticationType) => authType.selected,
      )?.length === 1
    );
    
  }

  onCancelClick() {
    this.cancelled.emit();
  }
}
