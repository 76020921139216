import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { Account } from '@app/core/models/portal/account';
import { Payee } from '@app/core/models/portal/payee';
import { User } from '@app/core/models/portal/user';
import { RecentActivity } from '@app/views/banking/shared/components/recent-activities-card/recent-activities-card.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankingDataService {
  constructor(private readonly http: HttpClient) {}

  addUser(userId: string, requestPayload: any) {
    return this.http.post(`add/user/${userId}`, requestPayload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(`users/${userId}`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  updateUser(userId: string, requestPayload: any) {
    return this.http.post(`update/user/${userId}/info`, requestPayload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  deleteUser(userId: string) {
    return this.http.delete(`delete/user/${userId}`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  getAccounts(userId: string): Observable<Account[]> {
    return this.http.get<Account[]>(`users/${userId}/accounts`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  getAccount(userId: string, accountNumber: number): Observable<Account> {
    return this.http.get<Account>(`users/${userId}/accounts/${accountNumber}`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  getAllRecentActivities(userId: string): Observable<RecentActivity[]> {
    return this.http.get<RecentActivity[]>(`users/${userId}/activities`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  getRecentActivitiesForAccount(
    userId: string,
    accountNumber: number,
  ): Observable<RecentActivity[]> {
    return this.http.get<RecentActivity[]>(
      `users/${userId}/accounts/${accountNumber}/activities`,
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
        }),
      },
    );
  }

  getPayees(userId: string): Observable<Payee[]> {
    return this.http.get<Payee[]>(`users/${userId}/payees`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  transferFunds(userId: string, payload: any) {
    
    return this.http.post(`transfer/account/users/${userId}`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  addPayee(userId: string, payload: any) {
    return this.http.post(`add/payee/users/${userId}`, payload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }

  validateUserAccount(userId: string, accountNumber: number) {
    return this.http.get(`validate/users/${userId}/accounts/${accountNumber}`, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.PORTAL_API,
      }),
    });
  }
}
