import { Component, HostListener, Input, Renderer2 } from '@angular/core';
import {
  CONSTS,
  IconType,
  IconTypes,
  InputElement,
  InputLabel,
  InputLabels,
  InputTypes,
} from './helpers';

const toggleMap: InputElement = {
  text: {
    type: InputTypes.PASSWORD,
    label: InputLabels.SHOW,
    icon: IconTypes.EYE,
  },
  password: {
    type: InputTypes.TEXT,
    label: InputLabels.HIDE,
    icon: IconTypes.EYE_SLASH,
  },
};

@Component({
  selector: 'app-toggle-view-password',
  templateUrl: './toggle-view-password.component.html',
  styleUrls: ['./toggle-view-password.component.scss'],
})
export class ToggleViewPasswordComponent {
  label: InputLabel = InputLabels.SHOW;
  icon: IconType = IconTypes.EYE;

  @Input() passwordEl!: HTMLInputElement;

  @HostListener('click') onClick() {
    if (!this.passwordEl) {
      return;
    }

    const inputEl = this.renderer
      .parentNode(this.passwordEl)
      .querySelector(CONSTS.INPUT);

    if (toggleMap.hasOwnProperty(inputEl.type)) {
      const property = toggleMap[inputEl.type as keyof InputElement];
      inputEl.type = property.type;
      this.label = property.label as InputLabel;
      this.icon = property.icon as IconType;
    }
  }

  constructor(private readonly renderer: Renderer2) {}
}
