import { Component, Input, OnInit,ViewChild, Output, EventEmitter } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import { HidAuthenticationService,LoaderService,HidRmsService, AuthService } from '@app/core/services';

@Component({
  selector: 'app-transaction-smsotp',
  templateUrl: './transaction-smsotp.component.html',
  styleUrls: ['./transaction-smsotp.component.scss']
})
export class TransactionSmsotpComponent implements OnInit {

  @Input() cancelBtn = false;
  @Input() fundsTransferInfo!: FundsTransfer;
  @Input() pushMessage!: string;
  @Output() cancelled = new EventEmitter();
  @Output() showhidapprovetargetview = new EventEmitter();
  @ViewChild('ngOtpInput', {static: false}) ngOtpInput: any;

  otp!: any;
  incorrectOtp = false;
  txChallengeId: string = '';

  // Contains auth_req_id used to validate the right user's response with right request
  hidAuthRequestId!: string;
  
  constructor(
    private readonly hidAuthService: HidAuthenticationService,
    private readonly hidRmsService: HidRmsService,
    private readonly loaderService: LoaderService,
    private readonly authService: AuthService) {
  //  super();
  }

  ngOnInit(): void {
    this.sendTransactionSigningSmsOtp()
  }

  sendTransactionSigningSmsOtp() {

    this.loaderService.show();
     this.hidAuthService.sendTransactionSmsOtp(this.pushMessage, 
       this._sendTransactionSmsOtpSuccess.bind(this),this._errorCB.bind(this))
  }


  _RMSPaymentSignSMS(){

    let app_payment_id = ''
    let scoring = this.hidRmsService.getPaymentScore()
    if( scoring.scoring_result ) { let e23 = scoring.scoring_result; if (e23.payments) { let e24 = e23.payments[0]; if (e24.app_payment_id) { app_payment_id = e24.app_payment_id } } } 

    
    let sigdate = new Date().toISOString().slice(0,10)
    

    
    const nowu = this.hidAuthService.getUser()
    let tokenid = '00000000000'
    if (nowu.phoneno) { let ccdscd = nowu.phone; if (ccdscd.number){ tokenid = ccdscd.number} }

    this.hidRmsService.paymentSign(nowu.userName, this.authService.getVisit()?.tttt, app_payment_id, "open", "accepted" ,sigdate, tokenid, 'sms', ()=>{})
  
  }
  
  _errorCB(response: any){
  }

  _sendTransactionSmsOtpSuccess(response: any){
    this.loaderService.hide();
    this.hidAuthRequestId = response['auth_req_id'];
    this.txChallengeId = response.attributes.filter((v: any) => v.name == `CHALLENGE.ID`)[0].value;
    /// signed via sms
    this._RMSPaymentSignSMS()
   

  }
  onOtpChange(otp: any){
    this.otp = otp;
  }

  onSubmit() {
    this.incorrectOtp = false;
    if (this.ngOtpInput?.otpForm?.invalid) {
      return;
    }
    // Authenticate TransactionSMSOTP
    this.hidAuthService.validateTransactionSmsOtp(
      this.otp,this.txChallengeId,
      () => {
        this.showhidapprovetargetview.emit();
      },
      () => {this.incorrectOtp = true;
           },
    );
  }  

  onViewChange(view: any) {
    if (
      [
        SDP_CONSTANTS.VIEWS.HID_APPROVE_PUSH,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_OTP,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_TRANS_OTP,
      ].includes(view)
    ) {
 //     this.activeView = view as string;
    } else {
 //     this.viewchange.emit(view);
    }
  }

  onCancelClick() {
    this.cancelled.emit();
  }

}
