import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SharedService } from '@app/core/services';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() modalRef = {} as BsModalRef;
  @Input() footer = false;
  @Input() closable = false;
  @Output() modalClose = new EventEmitter();

  constructor(private readonly sharedService: SharedService) {}

  ngOnInit() {
    this.sharedService.openModal();
  }

  onCloseBtnClick() {
    this.modalRef.hide();
    this.modalClose.emit();
  }

  ngOnDestroy() {
    this.sharedService.closeModal();
  }
}
