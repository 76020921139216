<div class="sdp-ob-form-header">
  <h3 class="sdp-ob-title">{{ 'hidApprovePush.title' | translate }}</h3>
  <p
    class="sdp-ob-intro"
    innerHTML="{{ translationRequired ? (message | translate) : message }}"
  ></p>
</div>

<div class="sdp-hid-approve-counter-container my-4">
  <circle-progress
    #circleProgress
    [title]="title"
    [percent]="percent"
    [maxPercent]="100"
    [options]="circleProgressOptions"
  ></circle-progress>
</div>

<div class="text-danger mx-auto my-3 w-80" *ngIf="error$$ | async as error">
  <small>{{ error }}</small>
</div>

<div class="text-center sdp-ob-intro">
  <ng-container *ngIf="!transactionSigning">
    <p>{{ 'hidApprovePush.useSecureCodeHelpText' | translate }}</p>
    <a class="sdp-dummy-link" (click)="onUseSecureCodeClick()">{{
      'hidApprovePush.useSecureCode' | translate
    }}</a>
  </ng-container>

  <ng-container *ngIf="transactionSigning"
    >Having troubles?
    <a class="sdp-dummy-link" (click)="resendPushNotification()">Click here</a>
    to send notification again.<br />Or alternatively use a
    <a class="sdp-dummy-link" (click)="onUseSecureCodeClick()">{{
      'hidApprovePush.useSecureCode' | translate
    }}</a></ng-container
  >
</div>

<div class="form-group text-right mt-3">
  <ng-container *ngIf="cancelBtn">
    <button
      class="btn btn-primary-mb {{ backBtn ? 'mr-3' : '' }}"
      (click)="onCancelClick()"
    >
      {{ 'cancel' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="backBtn">
    <button class="btn btn-primary-mb" (click)="onBackClick()">
      {{ 'hidApprovePush.back' | translate }}
    </button>
  </ng-container>
</div>
