import { Component } from '@angular/core';
import { LoaderService } from '@app/core/services';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  isLoading$: Observable<boolean>;

  constructor(private readonly loaderService: LoaderService) {
    this.isLoading$ = this.loaderService.isLoading.pipe(delay(0));
  }
}
