import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService {
  logError(error: any) {
    const date = new Date().toISOString();

    if (error instanceof HttpErrorResponse) {
      console.error(date, this._getHttpErrorMessage(error));
    } else if (error instanceof TypeError) {
      console.error(date, 'There was a Type error.', error.message);
    } else if (error instanceof Error) {
      console.error(date, 'There was a general error.', error.message);
    } else {
      console.error(date, error);
    }
  }

  private _getHttpErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401: {
        return `Unauthorized: ${error.message}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
