import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from '@core/services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-control-msg',
  templateUrl: './form-control-msg.component.html',
})
export class FormControlMsgComponent implements OnDestroy {
  errorMessage!: string;
  formControl!: AbstractControl;
  subscriptions: Subscription[] = [];

  @Input() set control(formControl: AbstractControl) {
    if (formControl) {
      this.formControl = formControl;
      this._subscribeForStatusChange();
      this._setErrorMessage();
    }
  }

  @Input() set formSubmitted(submitted: boolean) {
    submitted && this._setErrorMessage();
  }

  constructor(private readonly translateService: TranslateService) {}

  private _subscribeForStatusChange() {
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe(() => this._setErrorMessage()),
    );
  }

  private _setErrorMessage() {
    if (
      (this.formControl.touched || this.formControl.dirty) &&
      this.formControl.invalid
    ) {
      this._getErrorMessage();
    } else {
      this.errorMessage = '';
    }
  }

  private _getErrorMessage() {
    const errors = this.formControl.errors;

    let errorDisplayed = false;

    for (let propertyName in errors) {
      if (errors.hasOwnProperty(propertyName)) {
        const validationErrorMessage =
          ValidationService.getValidationErrorMessage(propertyName);

        this.translateService
          .get(validationErrorMessage, errors[propertyName])
          .subscribe((text: string) => {
            this.errorMessage = text;
            errorDisplayed = true;
          });

        if (errorDisplayed) {
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
