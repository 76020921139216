import { AddPayeeComponent } from '@app/views/banking/funds-transfer/add-payee/add-payee.component';
import { environment } from '@environments/environment';

const USER_ID = 'userId';

/**
 * Product Titles
 */

const PRODUCT_TITLES = {
  MAJOR_BANK_PORTAL: 'constants.productTitle.consumerJourney',
  IDENTITY_VERIFICATION: 'constants.productTitle.backOfficePortal',
  AUTH_ADMIN_PORTAL: 'constants.productTitle.authManagement',
  RISK_MGMT_SOL_DASHBOARD: 'constants.productTitle.riskManagement',
};



/**
 * Id-Pal Document Submission Events
 */

const HID_IDP_EVENTS = {
  NEW_SUBMISSION_START: 'new_submission_start',
  SUBMISSION_COMPLETE: 'submission_complete',
  CDD_GENERATED: 'cdd_complete',
};

/**
 * Application Routes
 */

const ROUTES = {
  HOME: '',
  BANKING: 'banking',
  IDP: 'idp',
  ONBOARDING: 'onboarding',
  DASHBOARD: 'dashboard',
  FUNDS_TRANSFER: 'fundstransfer',
  PROFILE_SETTINGS: 'profilesettings',
  HISTORY: 'history',
  SECURITY: 'security',
  IDPCALLBACK:'returnidp',
  INTFRAME:'integration',
};

const RECENT_ACTIVITY_DT_FORMAT = 'MMM d, y h:mm a';

/**
 * Views
 */

const VIEWS = {
  LOGIN: 'login',
  REGISTER: 'register',
  SEARCH_USER: 'searchuser',
  CREATE_PASSWORD: 'createpassword',
  FORGOT_PASSWORD_OTP: 'forgotpasswordotp',
  MANUAL_REGISTER: 'manualregister',
  MANUAL_REGISTER_SUCCESS: 'registersuccess',
  SELECT_ONBOARDING_PROCESS: 'selectonboardingprocess',
  ONBOARDING_STATUS: 'onboardingstatus',
  ONBOARDING_WEBVIEW: 'onboardingwebview',
  FLOW_END: 'flowend',
  FIRST_TIME_REG: 'firsttimeregistration',
  FIRST_TIME_REG_WITH_TEMP_PASS: 'firsttimeregistrationwithtemporarypassword',
  CHOOSE_AUTHENTICATION: 'chooseauthentication',
  CONNECT_HID_APPROVE: 'connecthidapprove',
  SELECT_PRIMARY_DEVICE: 'selectprimarydevice',
  HID_APPROVE_FLOW: 'hidapproveflow',
  HID_APPROVE_PUSH: 'hidapprovepush',
  HID_APPROVE_OTP: 'hidapproveotp',
  BANKING: 'banking',
  IDP: 'idp',
  IDPCALLBACK:'returnidp',
  HID_APPROVE_TRANS_OTP: 'transactionsigning',
  TRANSACTION_SIGN_SMSOTP: 'transactionsigningsmsotp',
  LOGIN_SMSOTP: 'loginsmsotp',
  ADD_DEVICE: 'adddevice',
  ADAPTIVE_AUTHENTICATION: 'adaptiveauthentication',
  ADAPTIVE_AUTHV2: 'adaptiveauthenticationv2',
  RMS_CHECK_START: 'rmscheckstart',
  RMS_CHECK_END: 'rmscheckend'
};

const TOKEN_TYPES = {
  BASIC: 'Basic',
  BEARER: 'Bearer',
};

const CONTEXT_TYPES = {
  API_TYPE: 'API_TYPE',
  AUTH_TOKEN_TYPE: 'AUTH_TOKEN_TYPE',
};

const API_TYPES = {
  HID_AUTH_API: 'HID_AUTH_API',
  HID_IDVA_API: 'HID_IDVA_API',
  HID_RMS_API: 'HID_RMS_API',
  HID_ARTS_API: 'HID_ARTS_API',
  PORTAL_API: 'PORTAL_APi',
};

const CONTENT_TYPES = {
  TEXT_HTML: 'text/html; charset=utf-8',
  APP_FORM_URLENCODED: 'application/x-www-form-urlencoded',
  APP_SCIM_JSON: 'application/scim+json',
  APP_JSON: 'application/json',
};

const ONBOARDING_TYPES = {
  DIGITAL: 'digitalonboarding',
  NuDIGITAL: 'newdigitalonboarding',
  MANUAL: 'manualonboarding',
  NuMANUAL: 'newmanualonboarding',
};
 
// Used in onboarding flow
const FLOW_TYPES: { [key: string]: string } = {
  TEMPORARY_PASSWORD: 'constants.onboardingFlow.tempPassword',
  PASSWORD: 'constants.onboardingFlow.password',
  REGISTERED: 'constants.onboardingFlow.registered',
  DELETE_ACCOUNT: 'constants.profileSettings.deleteAccount',
};

const FLOW_TYPES_TEMP: { [key: string]: string } = {
  SUCCESSFULLYREGISTERD: 'You have been successfully registered with Major Bank',
  SUCCESSFULLYDELETED: 'Your account has been deleted. Make sure to visit us again.'
};

const CHOOSE_AUTHENTICATION = {
  HID_APPROVE: 'chooseAuthentication.hidApprove',
  EMAIL: 'chooseAuthentication.email',
  SMS: 'chooseAuthentication.sms',
  HARDWARE_TOKEN: 'chooseAuthentication.hardwareToken',
};

const HID_APPROVE_MESSAGES = {
  LOGIN: `Please validate your login request to Internet Bank.`,
  ADD_PAYEE: `Please validate your add payee request for PAYEE_DETAILS at Internet Bank.`,
  FUNDS_TRANSFER: `Please validate your funds transfer request for CURRENCY_SYMBOLAMOUNT to PAYEE_DETAILS for Internet Bank.`,
  EDIT_PERSONAL_INFO: `Please validate your edit personal information request for Internet Bank.`,
  DELETE_TRUSTED_DEVICE: `Please validate your delete device request for DEVICE_DETAILS for Internet Bank`,
  DELETE_USER_ACCOUNT: `Please validate your delete account request for Internet Bank.`,
};

/**
 * Regular Expressions
 */

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const REGEX = {
  EMAIL: EMAIL_REGEX,
};

const OTP_FIELDS_LENGTH = {
  ACCOUNT_CODE: 4,
  OOBEML_OTP: 8,
  HID_APPROVE_SECURE_CODE: 6,
  HID_APPROVE_TRANS_SIGN_SECURE_CODE: 8,
  TRANS_SIGN_SMS_OTP:8,
};

const PUSH_DEVICE_TYPES = {
  DT_TDSV4SD: environment.hidAuth.pushDeviceType,
  DT_TDSV4Si: environment.hidAuth.pushDeviceType,
  DT_TDSV4: environment.hidAuth.pushDeviceType, // DT_TDSV4
  DT_TDSV4D: environment.hidAuth.pushDeviceType, // DT_TDSV4
};

const SESSION_USER = 'user';
const VISIT_TAG = 'tag';

// Setting default config for ngx bootstrap modal
// References: https://valor-software.com/ngx-bootstrap/#/modals#api-reference
const NGX_MODAL_DEFAULT_CONFIG = {
  ignoreBackdropClick: true, // to prevent closing of modal on clicking outside of it.
  keyboard: false, // to prevent closing of modal on Esc
};



const MFAV2_USECASES = {
  ADD_PAYEE: 'addpayee',
  EDIT_USER: 'edituser',
  DELETE_ACCOUNT: 'deleteaccount',
  TX_SIGN: 'txsign',
  LOGIN: 'login',
  ONBOARDING: 'onboarding'
}




export const SDP_CONSTANTS = {
  API_TYPES,
  CONTENT_TYPES,
  OTP_FIELDS_LENGTH,
  HID_IDP_EVENTS,
  ROUTES,
  REGEX,
  VIEWS,
  PRODUCT_TITLES,
  TOKEN_TYPES,
  CONTEXT_TYPES,
  ONBOARDING_TYPES,
  FLOW_TYPES,
  FLOW_TYPES_TEMP,
  CHOOSE_AUTHENTICATION,
  HID_APPROVE_MESSAGES,
  USER_ID,
  PUSH_DEVICE_TYPES,
  SESSION_USER,
  VISIT_TAG,
  RECENT_ACTIVITY_DT_FORMAT,
  NGX_MODAL_DEFAULT_CONFIG,
  MFAV2_USECASES,
};
