<div class="text-center">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">{{ 'connectHidApprove.title' | translate }}</h3>
    <p class="sdp-ob-intro" *ngIf="qrDesc">
      <ng-container *ngIf="!isMobile">
        {{ 'connectHidApprove.description1' | translate }}
      </ng-container>
      <ng-container *ngIf="isMobile">
        {{ 'connectHidApprove.mobiledescription1' | translate }}
      </ng-container>
    </p>
    <p class="sdp-ob-intro" *ngIf="manualDesc">
      {{ 'connectHidApprove.description2' | translate }}
    </p>
  </div>

  <div class="my-5 d-flex justify-content-center">
    <tabset (click)="fetchTab($event)">
      <tab
        [heading]="isMobile
         ? 'Link' : 'QR Code'"
        id="tab-1"
        ><ng-container [ngTemplateOutlet]="qrCodeTpl"></ng-container
      ></tab>
      <tab heading="Manual Code" id="tab-2"
        ><ng-container [ngTemplateOutlet]="manualCodeTpl"></ng-container
      ></tab>
    </tabset>
  </div>

  <div class="form-group text-right mt-3">
    <ng-container *ngIf="cancelBtn">
      <button class="btn btn-primary-mb mr-3" (click)="onCancelClick()">
        {{ 'cancel' | translate }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #qrCodeTpl>
  
  <div class="sdp-connect-hid-qrcode">
    <ng-container *ngIf="!deviceDetectorService.isMobile()">
      <div>
        <ng-container *ngIf="qrCodeData">
          <qrcode [qrdata]="qrCodeData | qrDeeplinkUrl" [width]="200"></qrcode>

          <div class="sdp-ob-intro mt-4">
            {{ 'connectHidApprove.registerInCurrentDevice' | translate
            }}<a [href]="qrCodeData | qrDeeplinkUrl" target="_blank">
              {{ 'clickHere' | translate }}</a
            >
          </div>
        </ng-container>
        <ng-container *ngIf="!qrCodeData">
          <img
            height="200"
            width="200"
            src="./../../../assets/images/hid-approve/qr-placeholder.jpg"
            alt="QR Code Placeholder"
          />
        </ng-container>
      </div>
    </ng-container>

    <div class="btn btn-secondary btn-lg text-white text-center m-auto" *ngIf="deviceDetectorService.isMobile()">
      <a class="text-white" [href]="qrCodeData | qrDeeplinkUrl" target="_blank">{{
        'connectHidApprove.enrollMobileDevice' | translate
      }}</a>
    </div>
  </div>
</ng-template>

<ng-template #manualCodeTpl>
  <div class="manual-code-alignment">
    <label class="text-bold">{{
      'connectHidApprove.userId' | translate
    }}</label>
    <span class=""> {{ manualCode.userId }}</span
    ><br />
    <label class="text-bold">{{
      'connectHidApprove.inviteCode' | translate
    }}</label>
    <span class=""> {{ manualCode.inviteCode }}</span
    ><br />
    <label class="text-bold">{{
      'connectHidApprove.serviceUrl' | translate
    }}</label>
    <span class="word-wrap"> {{ manualCode.serviceUrl }}</span
    ><br />
  </div>
</ng-template>
