import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Base } from '@app/core/base/base';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import { HidAuthenticationService, HidRmsService, AuthService } from '@app/core/services';

@Component({
  selector: 'app-transaction-signing-otp',
  templateUrl: './transaction-signing-otp.component.html',
  styleUrls: ['./transaction-signing-otp.component.scss'],
})
export class TransactionSigningComponent extends Base {
  @Input() view!: string;
  @Input() fundsTransferInfo!: FundsTransfer;
  @Output() showhidapprovetargetview = new EventEmitter();
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  otp!: any;
  incorrectOtp = false;

  constructor(
    private readonly hidAuthService: HidAuthenticationService, 
    private readonly hidRmsService: HidRmsService,    
    private readonly authService: AuthService 
    ) {
    super();
  }

  onOtpChange(otp: string) {
    this.otp = otp;
  }

  _RMSPaymentSignOTP(){

    let app_payment_id = ''
    let scoring = this.hidRmsService.getPaymentScore()
    if( scoring.scoring_result ) { let e23 = scoring.scoring_result; if (e23.payments) { let e24 = e23.payments[0]; if (e24.app_payment_id) { app_payment_id = e24.app_payment_id } } } 

    
    let sigdate = new Date().toISOString().slice(0,10)
    

    
    const nowu = this.hidAuthService.getUser()
    let tokenid = ''
    if (nowu.phoneno) { let ccdscd = nowu.phone; if (ccdscd.number){ tokenid = ccdscd.number} }
    tokenid = 'ApproveOTP' + tokenid
    this.hidRmsService.paymentSign(nowu.userName, this.authService.getVisit()?.tttt, app_payment_id, "open", "accepted" ,sigdate, tokenid, 'otp', ()=>{})
  
  }


  onSubmit() {
    this.incorrectOtp = false;

    if (this.ngOtpInput?.otpForm?.invalid) {
      return;
    }

    this.hidAuthService.authenticateHIDApproveTransSignSecureCode(
      this.otp,
      this._getTransactionSignDataString(),
      () => {
        this._RMSPaymentSignOTP()
        this.showhidapprovetargetview.emit();
      },
      () => (this.incorrectOtp = true),
    );
  }

  private _getTransactionSignDataString() {
    let data = `sign1:${this.fundsTransferInfo.payeeAccountNumber}:false`;

    if (this.fundsTransferInfo.amount) {
      data += ` sign2:${this.fundsTransferInfo.amount}:false`;
    }

    if (this.fundsTransferInfo.remarks) {
      data += ` sign3:${encodeURIComponent(
        this.fundsTransferInfo.remarks,
      )}:false`;
    }

    return data;
  }
}
