import { Directive, Input, ElementRef, HostListener } from '@angular/core';

const LIGHT_IMG_FILE_NAME_SUFFIX = '-active';

@Directive({
  selector: '[appHoverImg]',
})
export class HoverImgDirective {
  @Input() disabled = false;
  @Input() noEvents = false;

  constructor(private readonly el: ElementRef) {}

  @HostListener('mouseover') onMouseOver() {
    this._checkAndChangeSrc(true);
  }

  @HostListener('mouseout') onMouseOut() {
    this._checkAndChangeSrc(false);
  }

  private _checkAndChangeSrc(light: boolean) {
    if (!this.disabled && !this.noEvents) {
      this._changeSrc(light);
    }
  }

  private _changeSrc(light: boolean) {
    const imgEl = this.el.nativeElement.querySelector('img');
    const src = imgEl.getAttribute('src');
    const fileSubDirPath = src.substr(0, src.lastIndexOf('/') + 1);

    imgEl.setAttribute(
      'src',
      fileSubDirPath + this._getImageFileName(src, light),
    );
  }

  private _getImageFileName(src: string, light: boolean) {
    const fileName = src.substr(src.lastIndexOf('/') + 1);
    const fileNameWithoutExtension = fileName.substr(0, fileName.indexOf('.'));
    const fileExtension = fileName.substr(
      fileName.indexOf('.'),
      fileName.length,
    );

    let imageFileName = '';

    if (light) {
      imageFileName = fileNameWithoutExtension;
    } else if (fileNameWithoutExtension.includes(LIGHT_IMG_FILE_NAME_SUFFIX)) {
      imageFileName = fileNameWithoutExtension.substr(
        0,
        fileNameWithoutExtension.lastIndexOf(LIGHT_IMG_FILE_NAME_SUFFIX),
      );
    } else {
      imageFileName = fileNameWithoutExtension;
    }

    imageFileName += (light ? LIGHT_IMG_FILE_NAME_SUFFIX : '') + fileExtension;

    return imageFileName;
  }
}
