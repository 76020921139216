import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HidDeviceDataService {
  devicePushId!: string;
  owner!: string;

  // String made up of userExternalId+'-'+RANDOM_NUMBER
  deviceExternalId!: string;

  // Preferred HID Approve device
  selectedDevice!: any;

  // User devices
  devices!: any[];

  constructor(private readonly http: HttpClient) {}

  createDeviceTypePayload() {
    let todayDate = new Date();
    return {
      schemas: ['urn:hid:scim:api:idp:2.0:Device'],
      externalId: this.deviceExternalId,
      // type: 'DT_TDSV4',
      type: environment.hidAuth.pushDeviceType,  //DT_TDSV4
      status: {
        //The status when creating the device is PENDING
        status: 'PENDING',
        active: false,
        startDate: formatDate(todayDate, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US'),
        expiryDate: formatDate(
          todayDate.setDate(todayDate.getFullYear() + 10),
          'yyyy-MM-ddTHH:mm:ssZ',
          'en-US',
        ),
      },
    };
  }

  createBindDevicePayload(email: string) {
    let todayDate = new Date();
    return {
      schemas: ['urn:hid:scim:api:idp:2.0:Device'],
      id: +this.devicePushId,
      externalId: this.deviceExternalId,
      owner: {
        display: email,
      },
      status: {
        //The status when creating the device is PENDING
        status: 'PENDING',
        active: false,
        startDate: formatDate(todayDate, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US'),
        expiryDate: formatDate(
          todayDate.setDate(todayDate.getFullYear() + 10),
          'yyyy-MM-ddTHH:mm:ssZ',
          'en-US',
        ),
      },
    };
  }

  createDeviceProvisonPayload() {
    return {
      schemas: ['urn:hid:scim:api:idp:2.0:Provision'],
      deviceType: environment.hidAuth.pushDeviceType, // DT_TDSV4
      description:
        'did=' +
        this.devicePushId +
        ',' +
        'url=' +
        environment.hidAuth.serviceUrl +
        '/' +
        environment.hidAuth.tenantId +
        ',' +
        'pch=CH_TDSPROV,pth=AT_TDSOOB,pct=CT_TDSOOB,pdt=DT_TDSOOB,' +
        'cb_url=' +
        environment.hidAuth.callbackUrl +
        ',cb_notif_token=8d67dc78-7faa-4d41-aabd-67707b374255,mod=GEN,sec=',
      owner: {
        value: this.owner,
      },
      attributes: [
        {
          name: 'AUTH_TYPE',
          value: 'AT_SMK',
          readOnly: false,
        },
      ],
    };
  }

  //Function to create the device of the type DT_TDSV4F
  createDeviceType(requestPayload: any) {
    return this.http.post(`user/device/create`, requestPayload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  checkCallback(email: string){
    return this.http.post(`return/check`, {email:email}, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    })
  }


  doneCallback(email:string){
    return this.http.post(`return/checkdone`, {email:email}, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    })
  }


  // Function to bind the device created to the user
  bindDevice(requestPayload: any) {
    return this.http.put(`user/device/bind`, requestPayload, {
      params: {
        devicePushId: this.devicePushId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  // The response of this step will be the QR code that will be scan by the phone of the user
  createDeviceProvision(requestPayload: any) {
    return this.http.post(`user/device/provision`, requestPayload, {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }

  unassignDevice(deviceId: number) {
    return this.http.put(
      `user/device/unassign`,
      { deviceId },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
        }),
      },
    );
  }

  deleteDevice(deviceId: number) {
    return this.http.delete(`user/device/delete`, {
      params: {
        deviceId,
      },
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_AUTH_API,
      }),
    });
  }
}
