import { Injectable } from '@angular/core';
import { PasswordPolicy } from '@app/views/onboarding/shared/models/password-policy.interface';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  profileId = -1;

  // Cached email to be shown in the register and login screens.
  email!: string;

  userName!: string;

  // Cached password to be set for user post successfull device registration
  password!: string;

  flowType!: string;

  setFlowType(flowType: string) {
    this.flowType = flowType;
  }

  setProfileId(profileId: number) {
    this.profileId = profileId;
  }

  getProfileId() {
    return this.profileId;
  }

  getPasswordPolicy(passwordPolicy: any): {
    policyMap: any;
    passwordGuidelines: string[];
  } {
    const policyMap = Object.keys(passwordPolicy).reduce(
      (acc: any, curr: string) => {
        if (
          ((curr === 'notEnglish' && !passwordPolicy['notEnglish']) ||
            (curr !== 'notEnglish' && passwordPolicy['notEnglish'])) &&
          !acc.hasOwnProperty(curr)
        ) {
          const defaultPolicyObj = Object.assign({}, {
            valid: false,
            msg: '',
          } as PasswordPolicy);

          if (['minLength', 'maxLength'].includes(curr)) {
            acc['minMaxLength'] = defaultPolicyObj;
          } else {
            acc[curr] = defaultPolicyObj;
          }
        }
        return acc;
      },
      {},
    );

    return this._getPasswordGuidelinesAndMap(passwordPolicy, policyMap);
  }

  private _getPasswordGuidelinesAndMap(passwordPolicy: any, policyMap: any) {
    const passwordGuidelines = [];
    let index = 0;

    if (passwordPolicy.minLength && passwordPolicy.maxLength) {
      passwordGuidelines[index++] = policyMap.minMaxLength.msg =
        'passwordPolicy.minMaxLength';
    }

    if (passwordPolicy.notOldPassword) {
      policyMap.notOldPassword.valid = undefined;
      passwordGuidelines[index++] = policyMap.notOldPassword.msg =
        'passwordPolicy.notOldPassword';
    }

    if (passwordPolicy.notSequence) {
      passwordGuidelines[index++] = policyMap.notSequence.msg =
        'passwordPolicy.notSequence';
    }

    if (passwordPolicy.minDiffChars) {
      passwordGuidelines[index++] = policyMap.minDiffChars.msg =
        'passwordPolicy.minDiffChars';
    }

    if (passwordPolicy.notUserAttribute) {
      passwordGuidelines[index++] = policyMap.notUserAttribute.msg =
        'passwordPolicy.notUserAttribute';
    }

    if (!passwordPolicy.notEnglish) {
      passwordGuidelines[index] = policyMap.notEnglish.msg =
        'passwordPolicy.notEnglish';
    }

    return {
      policyMap,
      passwordGuidelines,
    };
  }
}
