<div class="text-center">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">
      {{ 'hidApproveTransSignOtp.title' | translate }}
    </h3>
    <p class="sdp-ob-intro">
      {{ 'hidApproveTransSignOtp.description' | translate }}
    </p>
  </div>

  <div class="row w-80 m-auto">
    <div class="col-6 w-100 text-left sdp-trans-sign">
      <div class="sdp-trans-sign-item">
        <strong>{{ 'hidApproveTransSignOtp.account' | translate }}:</strong>
        {{ fundsTransferInfo.payeeAccountNumber }}
      </div>

      <div class="sdp-trans-sign-item">
        <strong>{{ 'hidApproveTransSignOtp.amount' | translate }}:</strong>
        {{ fundsTransferInfo.amount }}
      </div>

      <div class="sdp-trans-sign-item">
        <strong>{{ 'hidApproveTransSignOtp.remarks' | translate }}:</strong>
        {{ fundsTransferInfo.remarks }}
      </div>
    </div>
    <div class="col-6">
      <!-- <qrcode
        [qrdata]="fundsTransferInfo | getCaptureModeQrUrl"
        [width]="150"
      ></qrcode> -->
    </div>
  </div>

  <div class="w-100 mt-4 mb-5 d-flex justify-content-center">
    <div class="form-group text-left">
      <label for="otp">{{
        'hidApproveTransSignOtp.secureCode' | translate
      }}</label>

      <ng-otp-input
        appNgotpinputKeyEvent
        [submitBtn]="submitBtn"
        #ngOtpInput
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length:
            SDP_CONSTANTS.OTP_FIELDS_LENGTH.HID_APPROVE_TRANS_SIGN_SECURE_CODE,
          allowNumbersOnly: true
        }"
      ></ng-otp-input>

      <div class="invalid-feedback d-block" *ngIf="incorrectOtp">
        {{ 'hidApproveTransSignOtp.incorrectOtp' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="text-right">
  <button
    type="submit"
    class="btn btn-primary-mb"
    (click)="onSubmit()"
    [disabled]="
      ngOtpInput?.otpForm?.invalid ||
      otp?.length !==
        SDP_CONSTANTS.OTP_FIELDS_LENGTH.HID_APPROVE_TRANS_SIGN_SECURE_CODE
    "
    #submitBtn
  >
    {{ 'confirm' | translate }}
  </button>
</div>
