import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';

@Injectable({
  providedIn: 'root',
})
export class HidRmsDataService {
  constructor(private readonly http: HttpClient) {}



  sessionHistory (  userid: string ) {           
        return this.http.post(`session/history`,  {userid},{
                                      context: setContext({
                                      apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
                                      })
        })
    }
    

  sessionCreateStep(  userid: string,
                      appactionid: string,
                      stepnumber: number,
                      useralias: string,
                      tokenid: string, 
                      tokentype: string,
                      stepresult: string,
                      sessioncontext: Object) {                 
      
    return this.http.post(`session/step`,  {
          userid,
          appactionid,
          stepnumber,
          useralias,
          tokenid,
          tokentype,
          stepresult,
          sessioncontext,
    },{
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
      }),
    }
    
    );
  }

  sessionLogin(   userid: string,
                  decision: string,
                  tmactionid:string,
                  signedappactionid:string,                  
                  sessioncontext: Object) {
      return this.http.post(`session/login`,  {
        userid,
        decision,
        tmactionid,
        signedappactionid,   
        sessioncontext,
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 
  
  sessionReject(  userid: string,
                  decision: string,
                  tmactionid:string,
                  signedappactionid:string,  
                  sessioncontext: Object) {
    return this.http.post(`session/reject`,  {
      decision,
      userid,
      tmactionid,
      signedappactionid,
      
      sessioncontext,
    },{
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
      }),
    }    
    );
  }

  sessionLogout(  userid: string,
                  appactionid: string, 
                  sessioncontext: Object) {
    return this.http.post(`session/logout`,  {
      userid,
      appactionid,
      sessioncontext,     
    },{
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
      }),
    })} 

    
  

  sessionInfo( 
                sessioncontext: Object) {
              return this.http.post(`session/info`,  {
                 
                  sessioncontext,     
              },{
                context: setContext({
                  apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
                })
              });
  }

  sessionScore(  userid: string,
    appactionid: string, 
    sessioncontext: Object) {
  return this.http.post(`session/score`,  {
  userid,
  appactionid,
  sessioncontext,     
  },{
    context: setContext({
      apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
    }),
  })    
  }  
  userInfo( userid: string) {
      return this.http.post(`user/info`,  {
      userid,        
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  }
  userDelete( userid: string) {
    return this.http.post(`user/delete`, {
    userid      }

    ,{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      })          
  }    
  paymentCreate(   userid: string,
                  appactionid: string,
                  //authorization_tm_action_id:string,
                  paymentarray:any,                  
                  sessioncontext: Object) {
     
      return this.http.post(`payment/create`,  {
        userid,
        appactionid,
        //authorization_tm_action_id,
        paymentarray,      
        sessioncontext,
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 
  paymentSign(    userid: string,
                  appactionid: string,                  
                  app_payment_id:string,
                  payment_state:string,
                  sigstate:string,
                  sigdate:string,
                  tokenid:string,
                  tokentype:string,   
                  sessioncontext: Object) {
      return this.http.post(`payment/sign`,  {userid,
        appactionid,
        app_payment_id,
        payment_state,
        sigstate,
        sigdate,
        tokenid,
        tokentype,
        sessioncontext,
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 

  paymentComplete(   
    app_payment_id:string,
    payment_state:string,
    session_context:Object
 ) {
return this.http.post(`payment/complete`,  {
  app_payment_id,
  payment_state,
  session_context
    },{
      context: setContext({
      apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
      }),
    }    
  );
} 


  actionCreate(   userid: string,
                  appactionid: string,
                  action:object,                                
                  sessioncontext: Object) {
      return this.http.post(`action/create`,  {userid,
        appactionid,
        action,    
        sessioncontext
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 
  actionReject(   userid: string,
                  decision: string,
                  tmactionid:string,
                  signedappactionid:string,                  
                  sessioncontext: Object) {
      return this.http.post(`action/login`,  {
        decision,
        userid,
        tmactionid,
        signedappactionid,   
        sessioncontext,
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 
  actionSign(   userid: string,
    appactionid: string,
    //authorization_tm_action_id:string,
    factor_index:Number,      
    signature_result:string,
   
    tokenid:string,
    tokentype:string,       
                  sessioncontext: Object) {
      return this.http.post(`action/sign`,  {
        userid,
        appactionid,
        //authorization_tm_action_id,
        factor_index,
        signature_result,
      
        tokenid,
        tokentype,
        sessioncontext        ,
      },{
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
        }),
      }    
      );
  } 
  actionComplete(   userid: string,
    decision: string,
    //signed_tm_action_id:string,
    appactionid: string
   ) {
      return this.http.post(`action/complete`,  {
        userid,
        decision,
        //signed_tm_action_id,
        appactionid
      },{
      context: setContext({
      apiType: SDP_CONSTANTS.API_TYPES.HID_RMS_API,        
      }),
      }    
);
  } 
}