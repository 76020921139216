import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';
import { Profile } from '@app/core/models';
import { HIDIdvaMessageResponse } from '@app/core/models/hid-idva-message.interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class HidIdvaDataService {
  constructor(
    private readonly http: HttpClient
  ) {}




  login() {
    return this.http.get('login', {
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
        contentType: SDP_CONSTANTS.CONTENT_TYPES.TEXT_HTML,
      }),
    });
  }
  verify(
    
    redirectref: string,
    accountCode:string = "default",
  ): Observable<HIDIdvaMessageResponse> {
    return this.http.post<HIDIdvaMessageResponse>(
      'verify',
     { 
      redirectRef: redirectref,
      accountCode: accountCode
      },
      {       
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
          // contentType: SDP_CONSTANTS.CONTENT_TYPES.TEXT_HTML,
         
        }),
      },
    );
  }
  txInfo(transactionID:string){
    return this.http.post(
      'statebyreqid',
      {
        txId: transactionID
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
          
        }),
      },
    );
  
  }
  statusByReqID(requestID:string) {
    return this.http.post(
      'statebyreqid',
      {
        reqid: requestID
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
          
        }),
      },
    );
  }
  terminate(requestID:string) {
    return this.http.post(
      'terminate',
      {
        reqid: requestID
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
          
        }),
      },
    );
  }

  statusByUID(UID:string) {
    return this.http.post(
      'statebyreqid',
      {
        uid: UID
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
        }),
      },
    );
  }

  decline(UID:string) {
    return this.http.post(
      'decline',
      {
        uid: UID
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
        }),
      },
    );
  }

  checkPostback(REQ:string) {
    return this.http.post(
      'postbackR',
      {
        reqid: REQ
      },
      {
        context: setContext({
          apiType: SDP_CONSTANTS.API_TYPES.HID_IDVA_API,
        }),
      },
    );
  }
}
