<form [formGroup]="form" (submit)="onSubmit()">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">
      {{ 'selectPrimaryDevice.title' | translate }}
    </h3>
  </div>

  <div class="form-container">
    <ng-container *ngFor="let device of devices">
      <div class="form-check">
        <label class="form-check-label" for="{{ device.id }}">
          <input
            class="form-check-input"
            type="radio"
            name="device"
            [id]="device.id"
            [value]="device.id"
            formControlName="device"
          />
          {{ device.friendlyName }}</label
        >
      </div>
    </ng-container>

    <div class="text-danger" *ngIf="error$$ | async as error">
      <small>{{ error }}</small>
    </div>
  </div>

  <div class="form-group text-right">
    <button type="submit" class="btn btn-primary-mb" [disabled]="form.invalid">
      {{ 'confirm' | translate }}
    </button>
  </div>
</form>
