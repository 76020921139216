import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlowEndComponent,
  FormControlMsgComponent,
  HidApproveComponent,
  HidApproveOtpComponent,
  HidApprovePushComponent,
  LoaderComponent,
  ModalComponent,
  SelectPrimaryDeviceComponent,
} from './components';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgOtpInputModule } from 'ng-otp-input';
import { QRCodeModule } from 'angularx-qrcode';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HoverImgDirective } from './directives/hover-img/hover-img.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TransactionSigningComponent } from './components/hid-approve/transaction-signing-otp/transaction-signing-otp.component';
import { GetCaptureModeQrUrlPipe } from './components/hid-approve/transaction-signing-otp/get-capture-mode-qr-url.pipe';
import { AddDeviceComponent } from './components/add-device/add-device.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { ChooseAuthenticationComponent } from './components/add-device/choose-authentication/choose-authentication.component';
import { ConnectHidApproveComponent } from './components/add-device/connect-hid-approve/connect-hid-approve.component';
import { QrDeeplinkUrlPipe } from './components/add-device/connect-hid-approve/qr-deeplink-url.pipe';
import { ToggleViewPasswordComponent } from './components/create-password/toggle-view-password/toggle-view-password.component';
import { NgotpinputKeyEventDirective } from './directives/ngotpinput-key-event/ngotpinput-key-event.directive';
import { TransactionSignAdaptiveComponent } from './components/transaction-sign-adaptive/transaction-sign-adaptive.component';
import { ChooseAuthenticationTransComponent } from './components/transaction-sign-adaptive/choose-authentication-trans/choose-authentication-trans.component';
import { TransactionSmsotpComponent } from './components/transaction-sign-adaptive/transaction-smsotp/transaction-smsotp.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';


@NgModule({
  declarations: [
    ModalComponent,
    FormControlMsgComponent,
    LoaderComponent,
    HidApproveComponent,
    HidApproveOtpComponent,
    HidApprovePushComponent,
    SelectPrimaryDeviceComponent,
    FlowEndComponent,
    HoverImgDirective,
    TransactionSigningComponent,
    GetCaptureModeQrUrlPipe,
    AddDeviceComponent,
    CreatePasswordComponent,
    ChooseAuthenticationComponent,
    ConnectHidApproveComponent,
    QrDeeplinkUrlPipe,
    ToggleViewPasswordComponent,
    NgotpinputKeyEventDirective,
    TransactionSmsotpComponent,
    TransactionSignAdaptiveComponent,
    ChooseAuthenticationTransComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TabsModule,
    NgOtpInputModule,
    QRCodeModule,
    TranslateModule,
    NgCircleProgressModule.forRoot(),
    NgxIntlTelInputModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalComponent,
    FormControlMsgComponent,
    TooltipModule,
    TabsModule,
    NgOtpInputModule,
    LoaderComponent,
    QRCodeModule,
    HoverImgDirective,
    NgxIntlTelInputModule,
    TranslateModule,
    HidApproveComponent,
    HidApproveOtpComponent,
    HidApprovePushComponent,
    SelectPrimaryDeviceComponent,
    FlowEndComponent,
    TransactionSigningComponent,
    ModalComponent,
    AddDeviceComponent,
    CreatePasswordComponent,
    ChooseAuthenticationComponent,
    ConnectHidApproveComponent,
    QrDeeplinkUrlPipe,
    ToggleViewPasswordComponent,
    NgotpinputKeyEventDirective,
    TransactionSmsotpComponent,
    ChooseAuthenticationTransComponent,
    TransactionSignAdaptiveComponent,  
  ],
})
export class SharedModule {}
