<div class="text-center">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">{{ 'hidApproveOtp.title' | translate }}</h3>
    <p class="sdp-ob-intro">
      {{ secureCodeDesc }}
    </p>
  </div>
  <div class="w-100 mt-4 mb-5 d-flex justify-content-center">
    <div class="form-group text-left">
      <label for="otp">{{ 'hidApproveOtp.otp' | translate }}</label>
      <ng-otp-input
        appNgotpinputKeyEvent
        [submitBtn]="submitBtn"
        #ngOtpInput
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length: SDP_CONSTANTS.OTP_FIELDS_LENGTH.HID_APPROVE_SECURE_CODE,
          allowNumbersOnly: true
        }"
      ></ng-otp-input>

      <div class="invalid-feedback d-block" *ngIf="incorrectOtp">
        {{ 'hidApproveOtp.incorrectOtp' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="form-group text-right mt-3">
  <ng-container *ngIf="cancelBtn">
    <button
      type="button"
      class="btn btn-primary-mb mr-3"
      (click)="onCancelClick()"
    >
      {{ 'cancel' | translate }}
    </button>
  </ng-container>

  <button
    type="submit"
    class="btn btn-primary-mb"
    (click)="onSubmit()"
    [disabled]="
      ngOtpInput?.otpForm?.invalid ||
      otp?.length !== SDP_CONSTANTS.OTP_FIELDS_LENGTH.HID_APPROVE_SECURE_CODE
    "
    #submitBtn
  >
    {{ 'confirm' | translate }}
  </button>
</div>
