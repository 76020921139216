import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments/environment';

@Pipe({
  name: 'qrDeeplinkUrl',
})
export class QrDeeplinkUrlPipe implements PipeTransform {
  transform(deviceProvisionMsg: string): string {
    return `https://approve.app.link/activate?name=${encodeURIComponent(
      environment.appName,
    )}&qrcode=${btoa(deviceProvisionMsg)}`;
  }
}
