<div class="modal-header">
  <h4 class="modal-title pull-left">
    <ng-content select="[modal-header]"></ng-content>
  </h4>
  <ng-container *ngIf="closable">
    <button
      aria-label="Close"
      type="button"
      class="close pull-right"
      (click)="onCloseBtnClick()"
    >
      <span>&times;</span>
    </button>
  </ng-container>
</div>
<div class="modal-body">
  <ng-content select="[modal-body]"></ng-content>
</div>
<div class="modal-footer" *ngIf="footer">
  <ng-content select="[modal-footer]"></ng-content>
</div>
