import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards';
import { SDP_CONSTANTS } from './core/helpers';
import { ProductsComponent } from './views/products/products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
  },
  {
    path: SDP_CONSTANTS.ROUTES.BANKING,
    loadChildren: () =>
      import('./views/banking/banking.module').then(m => m.BankingModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: SDP_CONSTANTS.ROUTES.IDP,
    loadChildren: () =>
      import('./views/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canLoad: [],
    canActivate: [],
  },
  {
    path: SDP_CONSTANTS.ROUTES.ONBOARDING,
    loadChildren: () =>
      import('./views/onboarding/onboarding.module').then(
        m => m.OnboardingModule,
      ),
  },
  {
    path: SDP_CONSTANTS.ROUTES.IDPCALLBACK,
    loadChildren: () =>
      import('./views/callback/callback.module').then(m => m.CallbackModule),
    canLoad: [],
    canActivate: [],
  },
  // Consider Delete and replace with inFrame component.  
  // {
  //   path: SDP_CONSTANTS.ROUTES.INTFRAME,
  //   loadChildren: () =>
  //     import('./views/integrate/integrate.module').then(m => m.IntegrateModule),
  //   canLoad: [],
  //   canActivate: [],
  // },
  //{ path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
