<div [ngClass]="idvonly ? 'sdp-idvonly-bg' : 'sdp-default-bg'">

  <div class="w-100" >

   

    <div class="page-column d-flex flex-column"> 
            <div [ngClass]="idvonly ? 'w-100 product-banner' : 'w-100 product-banner'  ">

              <ng-container *ngIf="idvonly">

                <video playsinline="" autoplay="" muted="" loop="" 
                data-src="./../../../assets/images/idv-banner.mp4" data-observed=""
                 src="./../../../assets/images/idv-banner.mp4"
                 >
                </video>
              </ng-container>
                
             




              <div class="sdp-product-header mt-4 mx-auto  w-75"> 


                  <ng-container *ngIf="!(idvonly)">
                  <div class="container sdp-product-text">
                    <h1 class="sdp-products-intro-heading">
                         Welcome to <b>HID&#174; Major Bank </b> demonstration.
                        </h1>
                        <p class="sdp-products-intro-desc">
                          {{ 'productComponent.description' | translate }}
                        </p>
                  </div>               
                </ng-container>
      


                    <ng-container *ngIf="idvonly">
                      <div class="container sdp-product-text">
                        <h1 class="sdp-products-intro-heading">
                          Welcome to <b>HID&#174; Identity Verification Service</b> demonstration.                         
                          
                        </h1>
                        <p class="sdp-products-intro-desc">              
                          HID’s Identity Verification Service (IDV) is an AI-powered solution for smooth digital onboarding. Our IDV solution goes beyond an onboarding solution, addressing multiple use case scenarios for customers such as account reactivation, expired document renewal, updating account details, registering a new device and more.
                        </p>
                      </div>
                      

                      
                      </ng-container>



        


          <ng-container *ngFor="let product of products">
            <div class="sdp-product-linkcard d-flex align-items-center justify-content-center" *ngIf="(product.enabled)">

              <a *ngIf="(product.idplink == 2)"
              class="                
              w-100 
              btn btn-secondary btn-lg 
              d-flex align-items-center justify-content-center
              "
              [href]="" 
                       
              style="height: 100%;"
              (click)="
                onProductClick(product, $event)
              "
              >
               
              <span>{{ product.title | translate }}</span>
            </a>


            <a *ngIf="(product.idplink == 1)"
            class="                
            w-100 
            btn btn-primary-mb btn-lg 
            d-flex align-items-center justify-content-center
            "
            [href]="idplink" 
                     
            style="height: 100%;"
            (click)="
              onProductClick(product, $event)
            "
            >
             
            <span>{{ product.title | translate }}</span>
          </a>

        
       
          <a *ngIf="(product.idplink == 0)"
          class="                
          w-100 
          btn btn-secondary btn-lg 
          d-flex align-items-center justify-content-center
          "
                  [href]="product.redirectLink"
                  style="height: 100%;"
                  (click)="
                    onProductClick(product, $event)
                  "
                  >
                      
                  <div class=>{{ product.title | translate }}</div>
        </a>
      </div>
          </ng-container>
      
          
    </div>
  </div>

   
      <div class="sdp-blog-articles w-75 mx-auto" style="background-color:#ffffff6f;">
        











     
      <div class="spacer-line w-100 mx-auto" style="height:25px; background-color:#ffffff3f"></div>
      <ng-container *ngFor="let article of items">
          <a    
            target="_blank"         
            [href]="article.link"    
            class="mx-auto sdp-ul-no-underline"
            >

            <div class="d-md-flex align-items-center justify-content-center" style="background-color:#ffffff">
              <div style="padding:2% 2% 2% 2%;"  class="sdp-article-image">
                <img 
                  class="img-fluid"
                  src="./../../../assets/images/products/{{
                    article.image
                  }}"
                  alt="{{ article.title }}"
                /></div>
              <div style="padding:2% 2% 2% 2%;" class="sdp-article-content w-75">
              <h2>{{ article.title }}</h2>
              <p>{{ article.contentSnippet }} </p>
              <h6>{{ article.pubDate }}</h6>
              <h6>{{ article.creator }}</h6>
            </div>
          </div>
          </a>
          <div class="spacer-line w-100 mx-auto" style="height:10px; background-color:#ffffffbf"></div>
    
      </ng-container>



        <div _ngcontent-ng-c2874729460="" class="d-md-flex align-items-center justify-content-center" style="background-color: #ffffff;"><a href="https://blog.hidglobal.com">Would you like to know more?
        </a></div>
        <div class="spacer-line w-100 mx-auto" style="height:25px; background-color:#ffffff3f"></div>
        <br />
        





     
    </div>
  </div>
</div>
