import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { setContext } from '@app/core/interceptors/api/api.interceptor';



@Injectable({
  providedIn: 'root',
})
export class BlogDataService {
  constructor(
    private readonly http: HttpClient,
 
  ) {}

  /**
   * Downloads Articles For Main Page
   */
  getArticles() {
    return this.http.get(`arts`,{
      context: setContext({
        apiType: SDP_CONSTANTS.API_TYPES.HID_ARTS_API
      }),
    });
  }

}
