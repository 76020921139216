import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HIDIdvaServiceCallbackEvent } from '@app/core/models';
import { HIDIdvaMessageResponse } from '@app/core/models/hid-idva-message.interface';
import { HidIdvaDataService } from '../hid-idva-data/hid-idva-data.service'
import {
  AuthService,
  HidAuthenticationService,
  LoaderService,
  HidAuthDataService,
} from '@app/core/services';
 
@Injectable({
  providedIn: 'root',
})
export class HidIdvaService {
  
  accessToken!: string;

 
  messageResponse!: HIDIdvaMessageResponse;

  
  callbackEvent!: HIDIdvaServiceCallbackEvent;
  constructor(
    private readonly authService: AuthService,
    private readonly hidAuthDataService: HidAuthDataService,
    private readonly   hidAuthenticationService:   HidAuthenticationService,
    private readonly hidIdvaDataService: HidIdvaDataService
    
  ) {
 
  }

 
  verify(
    redirectRef: string,   
    successCb: (response: any) => void,
    errorCb?: (error: HttpErrorResponse) => void,
    accountCode?: string,
  ){
   
      this.hidIdvaDataService
      .login() 
      .subscribe(
        ()=>{        
          this.hidIdvaDataService
            .verify(redirectRef, accountCode)
            .subscribe( (res:HIDIdvaMessageResponse) => {
          
             successCb( res )
              }, (error: HttpErrorResponse) => errorCb?.(error),
              );   
            },
        ()=>{
       
              this.hidIdvaDataService
                .verify(redirectRef,accountCode)
                .subscribe( (res:HIDIdvaMessageResponse) => {
                 successCb( res )
                  }, (error: HttpErrorResponse) => errorCb?.(error),
                  );
                 
                  
                })
        } 

  

  checkPostaback(REQ:string){
    this.hidIdvaDataService
    .login() 
    .subscribe(
      ( comeback:any )=>{
      this.hidIdvaDataService
      .checkPostback(REQ)
      .subscribe( (res:any) => {
    
        return res
      })
    })
  } 

  statusByReq(
    REQ:string,
    successCb: (response: any) => void,
    errorCb?: (error: HttpErrorResponse) => void,)
    {
      this.hidIdvaDataService
      .login() 
      .subscribe(
        ()=>{
          this.hidIdvaDataService
              .statusByReqID(REQ)
              .subscribe( 
                (res:any) => { 
                successCb(res)                
        })
      }
      ,
      ()=>{
      this.hidIdvaDataService
      .statusByReqID(REQ)
      .subscribe( 
        (res:any) => { 
        successCb(res)
        })
      })
    }
    
    transactionDetail(
      txId:string,
      successCb: (response: any) => void,
      errorCb?: (error: HttpErrorResponse) => void,)
      {
        this.hidIdvaDataService
        .login() 
        .subscribe(
          ()=>{
            this.hidIdvaDataService
                .txInfo(txId)
                .subscribe( 
                  (res:any) => { 
                  successCb(res)                
          })
        }
        ,
        ()=>{
        this.hidIdvaDataService
        .txInfo(txId)
                .subscribe( 
                  (res:any) => { 
                  successCb(res)                
          })
        }
      )}   
      
        


  statusByUID(
    UID:string,
    successCb: (response: any) => void,
    errorCb?: (error: HttpErrorResponse) => void,)
    {
    this.hidIdvaDataService
    .login() 
    .subscribe(
      ( comeback:any )=>{
        this.hidIdvaDataService
            .statusByUID(UID)
            .subscribe( 
              (res:any) => { 
                successCb(res)
        } )
      })
        
    
  }

  decline(UID:string){
      this.hidIdvaDataService
      .login() 
      .subscribe(
        ( comeback:any )=>{
          this.hidIdvaDataService
              .decline(UID)
              .subscribe( (res:any) => { return res }, (err:any) => {return err })

            })
          }
        
    
          
      
    
  terminate(REQ:string,
    successCb: (response: any) => void,
    errorCb?: (error: HttpErrorResponse) => void,){
      this.hidIdvaDataService
      .login() 
      .subscribe(
        ()=>{
          this.hidIdvaDataService
              .terminate(REQ)
              .subscribe( 
                (res:any) => { 
                successCb(res)                
        })
      }
      ,
      ()=>{
      this.hidIdvaDataService
      .terminate(REQ)
      .subscribe( 
        (res:any) => { 
        successCb(res)
        })
      })
      }
    }









