import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankingService {


  // Provide for rich Portal/BankingUserLogic
  // CreatePortalUserSafe
  // DeletePortalUserSafe
  // QueryPortalUsers { userid, detail[all,key,none(default)] }
  // QueryPortalUserDetail
  


  fundsTransfer$$ = new Subject();
  personalInfoUpdated$$ = new Subject();

  getFundsTransferSub() {
    return this.fundsTransfer$$.asObservable();
  }

  setFundsTransferSub() {
    this.fundsTransfer$$.next(true);
  }

  getPersonalInfoSub() {
    return this.personalInfoUpdated$$.asObservable();
  }

  setPersonalInfoSub() {
    this.personalInfoUpdated$$.next(true);
  }

  getTransferFundsPayload(form: any, payeeUserId: string) {
    return {
      fromAccountNumber: form.fromAccountNumber,
      payeeAccountNumber: form.payeeAccountNumber,
      payeeUserId,
      currency: form.currency,
      amount: form.amount,
      remarks: form.remarks,
    };
  }

  getAddPayeePayload(form: any) {
    return {
      accountNumber: form.accountNumber,
      payeeUserId: form.payeeUserId,
    };
  }
}
