import { Injectable } from '@angular/core';
import {   BlogDataService,
} from '@app/core/services';
import { HIDArtsArticle } from '@app/core/models/hid-arts-article';

@Injectable({
  providedIn: 'root',
})

export class BlogService {
  
  // Local Article Storage. Refresh with server once an hour. 
  storeArticle?: Array<HIDArtsArticle>;
  refreshtimeout: Number = 3600000;
  refreshlast: Number = 0;
  

  constructor( 
    private readonly blogDataService: BlogDataService,  
  ) {
   this.loadArticles()
    
    
  }


  loadArticles(){
    return this.blogDataService
          .getArticles()
          .subscribe( (res: any) => { 
          
      
            this.storeArticle = res.items
       
            
            return this.storeArticle
            } )
  }
}