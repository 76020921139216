import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SDP_CONSTANTS } from '@core/helpers';
import { HIDArtsArticle } from '@app/core/models/hid-arts-article';
import { environment } from '@environments/environment';
import { 
  AuthService,
  SharedService,
  HidRmsService,
  BlogDataService
} from '@core/services';
import { Observable } from 'rxjs';


interface IProduct {
  title: string;
  imageFileName: string;
  redirectLink?: string;
  routerLink?: string;
  idvlink?: boolean;
  enabled?: boolean;
  idplink?: number;
}

interface IDetection {
  id:string;
  type:string;
}

interface IRMSData {
android_device?: object;
geoip?: { city?: string; country_code?:string; latitude?:number; longitude?:number};
geolocation?: object;
ios_device?: object;
ip: { endpoint?:string; proxy_pierce?: string };
isp?: string;
tm_device_id?: string,
web_device?: object;
}
interface IRMSScore {
data: {
  all_data_collected: string;
  collected_data?: Array<string>;
  expected_data?: Array<string>;

};
scoring_result: {
    case_id?: string;
    risk: string;
    device: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
    session: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
    user: { detections?: Array<IDetection>; score?: string, standalone_signals?: Array<IDetection>}
}
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})

export class ProductsComponent {
  rmsdata: IRMSData[] = []
  rmsscore: IRMSScore[] = []

  secure?: boolean
  idplink: string = ''
 
  products: IProduct[] = []  
  ;
  idvonly:boolean = false;
 
  items?: Array<HIDArtsArticle>

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly authService: AuthService,
    private readonly hidRmsService:HidRmsService,
    private readonly blogDataService:BlogDataService,
  
  ) {
    
  }
  ngOnInit() {
  
    if (environment.module_auth){
      this.products = [
        {
         title: "See SaaS Identity Portal",
         imageFileName: '',
         redirectLink: this.idplink,
         enabled: true,
         idplink:1
       },
       {
         title: "Login Here",
         imageFileName: '',
         routerLink: SDP_CONSTANTS.ROUTES.BANKING,
         enabled: true,
         idplink:0    
       },   
     ]
      } 
    if (environment.module_idv2 && !(environment.module_auth)){
       this.idvonly = true 
        this.products = [
         {
           title: "To test HID IDV solution, click here.",
           imageFileName: '',
           idvlink:true,       
           enabled: true,
           idplink:2    
         },   
       ]
        }
    this._getArticles();
    this._addScripts();
    this._CheckRMS();
    this._getArticles();
    this._generateIDPLink();
    
    
  }
  ngOnDestroy() {

    
    
  }

 private _addScripts() {
  
  
 }


 private _RMSScoreData(){ 
  let RMSdata = this.hidRmsService.getScore()
  this.rmsscore.push(RMSdata)    
  this.secure = true
  
}

private _getArticles(){
    return this.blogDataService
          .getArticles()
          .subscribe( (res: any) => { 
          
      
            this.items = res.items
          
            for ( let item in this.items){
              let it = Number(item) + Number(1)
              this.items[Number(item)].image = '' + it + '-blog.jpg'
            }
          
            
            return this.items
            } )
  }
      


private _CheckRMS(){
  if (environment.module_rms) {
    if( this.authService.getCurrentUser()?.userExternalId) { 
      this.hidRmsService.sessionscore(this.authService.getCurrentUser()?.userExternalId, this.authService.getVisit().tttt, this._RMSScoreData.bind(this)) 
    } else { this.secure = false}      
  } else { this.secure = false }

}

 private _generateIDPLink(){
    let client_id = environment.hidAuth.clientId
    let state = this.authService.getVisit()?.tttt
    let nonce = this.authService.getVisit()?.tttt
    let scope = 'openid'
    let response_type= "code id_token"
    let env = environment.hidAuth.serviceUrl
    let ten = environment.hidAuth.tenantId
    let IDPbase = environment.hidAuth.IDPbase
    let redirect_uri = environment.hidAuth.redirect_uri
    

    // security check
    let acr = ''

    
    let acr1FA = 'acr_1fa'
    
    let acr2FA = 'acr_2fa'
    if(this.secure) {
        acr = acr1FA
    } else {
        acr = acr2FA
    }
    
    // RMS Bypass
    acr = acr1FA
    

    // this.idplink = 'https://' +  env + '/idp/' + ten + '/authn/login?' + 
    this.idplink =  '' + IDPbase +
                      '?state=' + state + '&'  + 
                      'scope=' + scope + '&' +
                      'nonce=' + nonce + '&' +
                      'response_type=' + response_type + '&' +
                      'client_id=' + client_id + '&' +
                      'redirect_uri=' + redirect_uri + "&" +
                      'acr_values=' + acr
    
    

 }

  onProductClick(product: IProduct, event: Event) {
    if (product.routerLink) {
      event.preventDefault();
      this.router.navigate([`/${product.routerLink}`], {
        relativeTo: this.activatedRoute,
      });
    } 
    if (product.redirectLink){  this.router.navigate([`${this.idplink }`])  }
    if (product.idvlink) {
    this.router.navigateByUrl(`/${SDP_CONSTANTS.ROUTES.ONBOARDING}`, {
      state: {
        initView: SDP_CONSTANTS.VIEWS.FLOW_END,
        idvOnly: true,
      }
  })
}
  }
}

