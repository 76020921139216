import { Directive, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

/**
 * To enable form submit on ENTER key press. 
 * Form must be a ReactiveForm. 
 * This directive will be available at app level. 
 * Do not change the selector.
 */

@Directive({
  selector: 'form[formGroup]',
})
export class FormDirective {
  @HostListener('keypress.enter') onKeyPress() {
    this.form.ngSubmit.emit();
  }

  constructor(private readonly form: FormGroupDirective) {}
}
