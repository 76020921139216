import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { OnboardingService,HidRmsService, AuthService } from '@app/core/services';
import { AddDeviceBase } from './add-device-base.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent extends AddDeviceBase implements OnInit {
  // Used to check if add device component is being
  // used as part of a flow or in standalone
  @Input() standalone = false;
  @Input() addAnotherDevice = false;
  @Input() cancelBtn = false;
  @Input() successScreen = false;
  @Input() successScreenMessage!: string;
  @Input() successScreenActionBtnText!: string;
  @Input() finalView!: string;
  @Output() flowend = new EventEmitter();
  @Output() adddeviceviewchange = new EventEmitter<string>();
  @Output() flowtype = new EventEmitter<string>();
  @Output() cancelled = new EventEmitter();

  // Depicts active view in Add Device flow.
  processingDone= false;
  activeView = SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION;

  constructor(
    private readonly onBoardingService: OnboardingService,
    private readonly hidRmsService:HidRmsService,
    private readonly authService:AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    // Required to emit for onboarding screen to adjust the layout

    if (this.addAnotherDevice) { this.activeView = SDP_CONSTANTS.VIEWS.ADAPTIVE_AUTHENTICATION;  }
    if (environment.module_rms) { this.RMSactionAddDeviceCreate() }
    this.adddeviceviewchange.emit(this.activeView);
  }


  RMSactionCreateObject(action_properties:Object, action_type:string ){
    return {action_properties:action_properties, action_type:action_type}
  } 


  // add RMS 

  RMSactionAddDeviceCreate(){

    let res = this.RMSactionCreateObject({deviceType:'approve'}, 'add-approve-device')
    this.hidRmsService.actionCreate(
      this.onBoardingService.email, 
      this.authService.getVisit()?.tttt,
      res,
      ()=>{} )
  }

  RMSactionAddDeviceSign(){
    this.hidRmsService.actionSign(
      this.onBoardingService.email,
      this.authService.getVisit()?.tttt, 
      1,
      "signature_accepted",
      "00000",
      "mtoken",
      ()=>{} )
  }

  RMSactionAddDeviceComplete(){
    let dec = {}
    this.hidRmsService.actionComplete(
      this.onBoardingService.email, 
      "authentication_scheme",
      ()=>{} )
  }




  ApproveConfirm(){
    if (environment.module_rms) { this.RMSactionAddDeviceSign() }
    this.processingDone = true;
    this.activeView = SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION;
  }
  onApproveConfirm(){
  
    this.adddeviceviewchange.emit(this.activeView);
  }

  onViewChange(view: any) {
   
    if (
      [
        SDP_CONSTANTS.VIEWS.ADAPTIVE_AUTHENTICATION,
        SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION,
        SDP_CONSTANTS.VIEWS.CONNECT_HID_APPROVE,
      ].includes(view)
    ) {
      this.activeView = view as string;
      this.adddeviceviewchange.emit(view);
    } else {
      if (!this.standalone) {
        this.flowtype.emit(this.onBoardingService.flowType);
        this.viewchange.emit(view);
      } else {
        this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
      }
    }
  }

  onFlowEnd() {
    if (environment.module_rms) { this.RMSactionAddDeviceComplete()}
    this.flowend.emit();
  }

  onCancelled() {
    this.cancelled.emit();
  }
}
