<div class="text-center">
  <div class="sdp-ob-form-header">

  <ng-container *ngIf="deleteAccount">
    <div class="my-5 justify-content-center">
      <i class="bi bi-padding bi-emoji-wink"  style="height: 128px; color: black; font-size:128px;"></i>
    </div>


    <h3 class="sdp-ob-title">We hope you had fun.</h3>


    <p class="sdp-ob-intro">
     Still, we are sorry to see you go. <br /> 
     Don't you want to try again? <br /> 
      <a class="sdp-dummy-link-ul" (click)="onActionBtnClick()">{{
        'searchUser.registerNow' | translate
      }}</a>
    </p>
  </ng-container>

  <ng-container *ngIf="!deleteAccount">


    <h3 class="sdp-ob-title">{{ 'flowEnd.success' | translate }}</h3>
    <p
      class="sdp-ob-intro"
      innerHTML="{{ translationRequired ? (message | translate) : message }}"
    ></p>
  

  <div class="my-5 justify-content-center">
    <i class="bi bi-padding bi-check-circle"  style="height: 128px; color: green; font-size:128px;"></i>
  </div>

 

  <ng-container *ngIf="idvonly">
  <button class="btn btn-primary-mb" (click)="navigateHome()">
    Want to try again? Go back to homepage.
 </button>
</ng-container>
<ng-container *ngIf="!idvonly">
      <button class="btn btn-primary-mb" (click)="onActionBtnClick()">
        {{ actionBtnText | translate }}
      </button>
  
    </ng-container>
  
 

  
    
  </ng-container>
  </div>
</div>
