import { Component } from '@angular/core';
import { Base } from '@app/core/base/base';

@Component({
  selector: 'add-device-base',
  template: '',
})
export class AddDeviceBase extends Base {
  constructor() {
    super();
  }
}
