<form [formGroup]="form" class="securedLogForm" (ngSubmit)="onSubmit()">
  <div class="sdp-ob-form-header">
    <h3 class="sdp-ob-title">{{ 'createPassword.title' | translate }}</h3>
    <p class="sdp-ob-intro">
      {{ 'createPassword.description' | translate }}
      <span
        class="sdp-dummy-link d-inline-block"
        [tooltip]="passwordGuidelinesTpl"
        container="body"
        containerClass="sdp-pwd-guidelines"
        >{{ 'createPassword.reviewPassword' | translate }}&nbsp;<i
        class="bi bi-question-circle-fill"
        ></i
      ></span>
    </p>
  </div>

  <div class="form-group position-relative">
    <app-toggle-view-password
      [passwordEl]="passwordEl"
    ></app-toggle-view-password>
    <label for="password">{{
      'createPassword.createPassword' | translate
    }}</label>
    <input
      id="password"
      type="password"
      class="form-control securedLogPassword"
      formControlName="password"
      [maxLength]="passwordPolicy?.maxLength"
      #passwordEl
      (input)="validate(passwordEl.value)"
    />

    <ng-container *ngIf="passwordCtrl?.valid">
      <ng-container *ngFor="let policyMap of policy.policyMap | keyvalue">
        <div class="sdp-pwd-validation-wrapper">
          <ng-container
            *ngIf="
              $any(policyMap.value).valid !== undefined &&
              $any(policyMap.value).valid
            "
          >
          <i class="bi bi-padding bi-check-circle-fill"></i>
          </ng-container>

          <ng-container
            *ngIf="
              $any(policyMap.value).valid !== undefined &&
              !$any(policyMap.value).valid
            "
          >
            <i class="bi bi-padding bi-x-circle-fill"></i>
          </ng-container>

          <ng-container *ngIf="$any(policyMap.value).valid !== undefined">
            <div
              [ngClass]="
                $any(policyMap.value).valid ? 'status match' : 'status unmatch'
              "
            >
              {{ $any(policyMap.value).msg }}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="passwordCtrl?.invalid">
      <app-form-control-msg
        [control]="form.controls.password"
        [formSubmitted]="submitted"
      ></app-form-control-msg>
    </ng-container>
  </div>

  <div class="form-group position-relative">
    <app-toggle-view-password
      [passwordEl]="confirmPassword"
    ></app-toggle-view-password>
    <label for="currentPassword">{{
      'createPassword.reenterPassword' | translate
    }}</label>
    <input
      id="confirmPassword"
      type="password"
      class="form-control securedLogPassword"
      formControlName="confirmPassword"
      [maxLength]="passwordPolicy?.maxLength"
      #confirmPassword
    />
    <app-form-control-msg
      [control]="form.controls.confirmPassword"
      [formSubmitted]="submitted"
    ></app-form-control-msg>
  </div>

  <ng-container
    *ngIf="
      form.controls.password.valid &&
      form.controls.confirmPassword.valid &&
      form.errors?.mustMatch
    "
  >
    <div class="text-danger">
      <small>{{ 'validations.mustMatch' | translate }}</small>
    </div>
  </ng-container>

  <div class="form-group text-right">
    <button
      type="submit"
      class="btn btn-primary-mb securedLogSubmit"
      [disabled]="form.invalid || !isPasswordValid()"
    >
      {{ 'submit' | translate }}
    </button>
  </div>
</form>

<ng-template #passwordGuidelinesTpl>
  <p>{{ 'createPassword.passwordGuidelines' | translate }}</p>
  <ol type="1">
    <li *ngFor="let guideline of policy.passwordGuidelines">{{ guideline }}</li>
  </ol>
</ng-template>
