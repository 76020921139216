import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Base } from '@app/core/base/base';
import { SDP_CONSTANTS } from '@app/core/helpers';
import { FundsTransfer } from '@app/core/models/portal/funds-transfer';
import { LoaderService } from '@app/core/services';

@Component({
  selector: 'app-transaction-sign-adaptive',
  templateUrl: './transaction-sign-adaptive.component.html',
  styleUrls: ['./transaction-sign-adaptive.component.scss']
})
export class TransactionSignAdaptiveComponent extends Base implements OnInit {

  @Input() standalone = true;
  @Input() translationRequired = true;
  @Input() isAddPayee!: boolean;
  @Input() isEditUser!: boolean;
  @Input() trustedDevice!: boolean;
  @Input() deleteUserAccount!: boolean;
  //@Input() activeView!: string;
  @Input() pushMessage!: string;
  @Input() message!: string;
  @Input() successScreen = false;
  @Input() backBtn = false;
  @Input() cancelBtn = false;
  @Input() successScreenMessage!: string;
  @Input() successScreenActionBtnText!: string;
  @Input() finalView!: string;
  @Input() transactionSigning = false;
  @Input() fundsTransferInfo!: FundsTransfer;
  @Input() showProcessing = false;
 // @Input() processingDone = false;
  
  @Output() showhidapprovetargetview = new EventEmitter();
  @Output() showChooseAuthentication = new EventEmitter();
  @Output() flowend = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @Input() set processingDone(processingDone: boolean) {
    if (processingDone !== undefined && processingDone && this.successScreen) {
      this.loaderService.hide();
      this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
    }
  }

  activeView = SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION;
  
  constructor(
    private readonly loaderService: LoaderService,
  ) { 
    super();
  }

  ngOnInit(): void {
  //  this.showChooseAuthentication.emit(this.activeView);
  }
 
  onViewChange(view: any) {
    if (
      [
        SDP_CONSTANTS.VIEWS.CHOOSE_AUTHENTICATION,
        SDP_CONSTANTS.VIEWS.HID_APPROVE_FLOW,
        SDP_CONSTANTS.VIEWS.TRANSACTION_SIGN_SMSOTP,
      ].includes(view)
    ) {
      this.activeView = view as string;
 //     this.adddeviceviewchange.emit(view);
    } else {
      if (!this.standalone) {
   //     this.flowtype.emit(this.onBoardingService.flowType);
        this.viewchange.emit(view);
      } else {
        this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
      }
    }
    }


    onShowHIDApproveTargetView() {
      if (this.showProcessing) {
        this.showhidapprovetargetview.emit();
        this.loaderService.show();
      } else {
        if (this.successScreen) {
          this.activeView = SDP_CONSTANTS.VIEWS.FLOW_END;
        } else {
          this.showhidapprovetargetview.emit();
        }
      }
    }

  onFlowEnd() {
    this.flowend.emit();
  }

  onCancelled() {
    this.cancelled.emit();
  }
  
}
