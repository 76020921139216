import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SDP_CONSTANTS } from '@core/helpers';
import { AddDeviceBase } from '../add-device-base.component';

interface IAuthenticationType {
  title: string;
  imageFileName: string;
  selected: boolean;
  isDisabled: boolean;
}

@Component({
  selector: 'app-choose-authentication',
  templateUrl: './choose-authentication.component.html',
  styleUrls: ['./choose-authentication.component.scss'],
})
export class ChooseAuthenticationComponent
  extends AddDeviceBase
  implements OnInit
{
  @Input() cancelBtn = false;
  @Output() cancelled = new EventEmitter();
  isSmsClicked = false;
  isAuthChecked = false;
  isHIDClicked = false;

  authDisable = false;
  // Contains all the authentication types.
  // HID Approve, Email, SMS, Hardware Token
  authenticationTypes!: IAuthenticationType[];

  // Contains only active authentication types
  // filtered by isDisabled flag
  activeAuthTypes!: IAuthenticationType[];

  constructor() {
    
    super();
  }

  ngOnInit(): void {
    this.createAuthenticationType();
    this._setActiveAuthTypes();
 
    //this._skipToApprove()
  }



  private _skipToApprove() {
    
    this.isHIDClicked = true;
    this.isSmsClicked = false;
    this.isAuthChecked = true;
    //this.authDisable = true;
    this.authenticationTypes = [
      {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
        imageFileName: 'approve',
        selected: true,
        isDisabled: this.authDisable,
      },
    ];
  
    this.viewchange.emit(SDP_CONSTANTS.VIEWS.CONNECT_HID_APPROVE);
  
   }




  createAuthenticationType() {
    this.authenticationTypes = [
      {
        title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HID_APPROVE,
        imageFileName: 'approve',
        selected: true,
        isDisabled: false,
      },
      // {
      //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.EMAIL,
      //   imageFileName: 'email',
      //   selected: false,
      //   isDisabled: true,
      // },
      // {
      //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.SMS,
      //   imageFileName: '!sms',
      //   selected: false,
      //   isDisabled: true,
      // },
      // {
      //   title: SDP_CONSTANTS.CHOOSE_AUTHENTICATION.HARDWARE_TOKEN,
      //   imageFileName: 'hardware-token',
      //   selected: false,
      //   isDisabled: true,
      // },
    ];
  }

  private _setActiveAuthTypes() {
    this.activeAuthTypes = this.authenticationTypes.filter(
      (authType: IAuthenticationType) => !authType.isDisabled,
    );
  }

  onSubmit() {
    if (this.isValid()) {
   
      this.viewchange.emit(SDP_CONSTANTS.VIEWS.CONNECT_HID_APPROVE);
    }
  }

  isValid() {
    return (
      this.activeAuthTypes.filter(
        (authType: IAuthenticationType) => authType.selected,
      )?.length === 1
    );
  }

  onCancelClick() {
    this.cancelled.emit();
  }
}
