import { FormGroup } from '@angular/forms';
import { Base } from './base';

/**
 * Base form which can be extended by child forms.
 * Contains properties which is generally used by
 * most of the forms.
 * @extends Base  
 */

export class BaseForm extends Base {
  // Represents the form of child component.
  declare form: FormGroup;

  // Flag indicating form has been submitted or not
  submitted = false;

  constructor() {
    super();
  }

  /**
   * Must be called by child's form submit method.
   * - Clears the previous error.
   * - Marks the submitted flag as true.
   * - Marks all the form controls as touched to run the validations.
   */
  submit() {
    this.error$$.next('');
    this.submitted = true;
    this.form?.markAllAsTouched();
  }
}
